import React, { useState } from 'react';
import { Sidebar } from '../components/Sidebar';
import { ShopHeader } from '../components/ShopHeader';
import '../styles/pages/general.css';
import '../styles/pages/signin.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Box, Button, CircularProgress, styled } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { green, blueGrey, grey } from '@material-ui/core/colors';
import InputMask from 'react-input-mask';
import Api from '../helpers/Api';
import SnackBarNotification from '../components/SnackBarNotification';
import { Link } from 'react-router-dom';

export function SignUp() {

    const [signingUp, setSigningUp] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('');
    
    const SignupButton = styled(Button)(({ theme }) => ({
        width: '100%',
        color: theme.palette.getContrastText(green[800]),
        marginTop: 20,
        backgroundColor: green[500],
        '&:hover': {
          backgroundColor: green[800],
        },
    }));
    
    const SigninButton = styled(Button)(({ theme }) => ({
        width: '100%',
        color: theme.palette.getContrastText(grey[200]),
        marginTop: 20,
        backgroundColor: grey[300],
        '&:hover': {
            backgroundColor: blueGrey[700],
            color: theme.palette.getContrastText(grey[900]),
        },
    }));

    const history = useHistory();

    const handleOpenSignin = () => {
        history.push('/signin');
    }

    const LoginSchema = Yup.object().shape({
        name: Yup.string()
          .min(2, 'Too Short!')
          .max(30, 'Too Long!')
          .required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
        cellphone: Yup.string()
            .transform(value => value.replace(/[^\d]/g, ''))
            .min(10, 'Invalid cellphone number')
            .max(10, 'Invalid cellphone number')
            .required('Required'),
        password: Yup.string()
            .min(8, 'Must have at least 8 characteres')
            .max(15, 'Max of 15 characteres allowed')
            .required('At least 8 characters length'),
        password2: Yup.string()
            .min(8, 'Too Short!')
            .max(15, 'Too Long!')
            .required('Passwords must match')
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    const {
        values: { cellphone },
        handleChange,
        handleSubmit,
        handleBlur,
        setFieldTouched,
        values,
        errors,
        touched
      } = useFormik({
        validationSchema: LoginSchema,
        initialValues: { email: '', password: '', password2: '', cellphone: '', name: '' },
        onSubmit: values => {
            signUpUser(values);
        }
    });

    async function signUpUser(data) {
        setSigningUp(true);
        try {
            const establishment = JSON.parse(localStorage.getItem('@appgenie/establishment')) || [];
            let payload = {
                user_name: data.name,
                user_email: data.email,
                user_password: data.password,
                user_phone: data.cellphone.replace(/[^\d]/g, ''),
                app_id: establishment.establishment_fk_app_id,
            }

            let res = await Api.signUp(payload);
            if (res.code === 201) {
                setSnackbarMessage('You are now logged in');
                setSnackbarType('success');
                setSnackbarOpen(true);
                setUserInfo(res);
            } else if (res.code === 202) {
                    setSnackbarMessage('User already exists, please use sign in option instead');
                    setSnackbarType('warning');
                    setSnackbarOpen(true);
            } else {
                setSnackbarMessage('An error has occurred. Try again later.');
                setSnackbarType('error');
                setSnackbarOpen(true);
            }

        } catch(err) {

            setSnackbarMessage('An error has occurred. Try again later.');
            setSnackbarType('error');
            setSnackbarOpen(true);
        }
        setSigningUp(false);
    }

    function setUserInfo(res) {

        res.data.loginTime = new Date().getTime();
        localStorage.setItem('@appgenie/user', JSON.stringify(res.data));

        // console.log('Is user verified?');
        // console.log(res.data.user_verified);

        if (res.data.user_verified === 0 || res.data.user_verified === false) {
            history.push('/verify-account');
        } else {
            
            let app_url = localStorage.getItem('@appgenie/app_url') || false;
            history.push(`/app/${app_url}`);
        }

        /* @TODO
        var hours = 24; // Reset when storage is more than 24hours
        var now = new Date().getTime();
        var setupTime = localStorage.getItem('loginTime');
        if(now-setupTime > hours*60*60*1000) {
            localStorage.clear()
        }*/
    }

    function handleCloseSnackbar() {
        setSnackbarOpen(false);
    }

    const change = (cellphone, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(cellphone, true, false);
    };

    return (
        <div className="container-wrapper">
            <div className="sidebar">
                <Sidebar />
            </div> 
            <div className="signin-wrapper">

                <ShopHeader 
                    title={`Create a new account`}
                    totalItems={0}
                />

                <Box 
                    component="form"
                    className="form-wrapper"
                    sx={{ p: 2, alignSelf: 'center' }}
                >

                    <TextField
                        style={{width: 350}}
                        required
                        id="name"
                        label="Name"
                        onBlur={handleBlur('name')}
                        onChange={handleChange('name')}
                        touched={touched.name ? 1: 0}
                        helperText={errors.name ? errors.name : ' '}
                        error={errors.name ? true : false}
                    />

                    <TextField
                        style={{width: 350}}
                        required
                        id="email"
                        label="E-mail"
                        onBlur={handleBlur('email')}
                        onChange={handleChange('email')}
                        touched={touched.email ? 1: 0}
                        helperText={errors.email ? errors.email : ' '}
                        error={errors.email ? true : false}
                    />

                    <InputMask
                        mask="999 999 9999"
                        // onChange={t=>setCellphone(t.target.value)}
                        value={cellphone}
                        onChange={change.bind(null, "cellphone")}
                    >

                    {(userCellphone) =>  <TextField
                                style={{width: 350}}
                                required
                                id="cellphone"
                                label="Cellphone"
                                name="cellphone"
                                // onBlur={handleBlur('cellphone')}
                                // onChange={handleChange(userCellphone)}
                                touched={touched.cellphone ? 1: 0}
                                // value={userCellphone}
                                helperText={errors.cellphone ? errors.cellphone : ' '}
                                error={errors.cellphone ? true : false}
                        />}
                    </InputMask>

                    <TextField
                        style={{width: 350}}
                        required
                        id="password"
                        label="Password"
                        type="password"
                        onBlur={handleBlur('password')}
                        onChange={handleChange('password')}
                        touched={touched.password ? 1: 0}
                        helperText={errors.password ? errors.password : 'At least 8 characters length'}
                        error={errors.password ? true : false}
                    />

                    <TextField
                        style={{width: 350}}
                        required
                        id="password2"
                        label="Retype password"
                        type="password"
                        onBlur={handleBlur('password2')}
                        onChange={handleChange('password2')}
                        touched={touched.password2 ? 1: 0}
                        helperText={errors.password2 ? errors.password2 : 'Must match the field above'}
                        error={errors.password2 ? true : false}
                    />

                    <a
                        className="termsOfService"
                        onClick={() => {}}
                        target='_blank'
                        href={`https://privacy.appgenie.site`}
                    >
                        By clicking below, you agree with our Terms of Service and Privacy Policy
                    </a>

                    <SignupButton 
                        variant="contained" 
                        onClick={handleSubmit}
                        disabled={signingUp}
                    >
                        {signingUp ? 
                            <CircularProgress size={22} color='#fff' />
                            :
                            <span>Sign up</span>
                        }
                    </SignupButton>
                    
                    <SigninButton variant="contained" onClick={handleOpenSignin}>
                        I already have an account
                    </SigninButton>
                </Box>

            </div>
            <SnackBarNotification 
                handleClose={handleCloseSnackbar}
                open={snackbarOpen}
                vertical={`bottom`}
                horizontal={`right`}
                message={snackbarMessage}
                type={snackbarType}
            />
        </div>
    )
}