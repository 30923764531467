import React from 'react';
import { Helmet } from 'react-helmet';


export const CustomTheme = ({bgcolor, textcolor, establishmentName, appUrl=null }) => {
    return (
    <Helmet>
        <title>{`${establishmentName} - Web Ordering`}</title>
        <style type="text/css">{`
            .app-sidebar,
            .cart,
            .app-home aside {
                background-color: ${bgcolor} !important;
            }
            .app-home aside footer strong {
                color: ${textcolor} !important;
            }
            .app-sidebar button {
                backgound: ${textcolor} !important;
            }
        `}</style>
        
        {appUrl == 'pizzeria-sao-paulo' && (
        <script key="function" id="facebook-pixel-script">
            {`!function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '278978728048067');
                fbq('track', 'PageView');`}
        </script>
        )}
        {appUrl == 'pizzeria-sao-paulo' && (
        <noscript key="image" id="facebook-pixel-image">{`278978728048067`}</noscript>
        )}
        
    </Helmet>);
}
