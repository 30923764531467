import React, { useState, useEffect, useRef } from "react";
// import { TextField } from "@material-ui/core";
import '../styles/components/search-input-location.css';
import PlacesAutocomplete from 'react-google-places-autocomplete';

let autoComplete;

const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
};



function SearchLocationInput({ setNewAddressInfo, setNewAddressButtonDisabled }) {
    const [query, setQuery] = useState("");
    const [location, setLocation] = useState('');
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const autoCompleteRef = useRef(null);

    function handleQuery(value) {
        setQuery(value);
        if (value.length === 0) {
            setNewAddressButtonDisabled(true);
        }
    }

    function handleScriptLoad(updateQuery, autoCompleteRef) {
        autoComplete = new window.google.maps.places.Autocomplete(
            autoCompleteRef.current,
            { types: ["address"], componentRestrictions: { country: "us" } }
        );
        autoComplete.setFields(["address_components", "formatted_address", 'geometry']);
        autoComplete.addListener("place_changed", () =>
            handlePlaceSelect(updateQuery)
        );
    }

    async function handlePlaceSelect(updateQuery) {
        const addressObject = autoComplete.getPlace();
        const query = addressObject.formatted_address;
        updateQuery(query);
        console.log(addressObject);
        console.log(addressObject.geometry);

        var componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            postal_code: 'short_name'
        };

        var newAddress = {}
        try {
            for (var i = 0; i < addressObject.address_components.length; i++) {
                var addressType = addressObject.address_components[i].types[0];
                if (componentForm[addressType]) {
                    newAddress[addressType] = addressObject.address_components[i][componentForm[addressType]]
                }
            }
            newAddress.lat = addressObject.geometry.location.lat();
            newAddress.lng = addressObject.geometry.location.lng();

        } catch(err) {
            console.log(err);
        }

        console.log(newAddress);
        // setNewAddressInfo(newAddress);

    }

    async function handlePlaceAttributes(addressObject) {

        var componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            postal_code: 'short_name'
        };

        var newAddress = {}
        try {
            for (var i = 0; i < addressObject.address_components.length; i++) {
                var addressType = addressObject.address_components[i].types[0];
                console.log(addressType);
                if (componentForm[addressType]) {
                    newAddress[addressType] = addressObject.address_components[i][componentForm[addressType]]
                }
            }
            newAddress.lat = addressObject.geometry.location.lat;
            newAddress.lng = addressObject.geometry.location.lng;

        } catch(err) {
            console.log(err);
        }

        console.log('-------------------------------------');
        console.log(addressObject.address_components.length);
        console.log(newAddress);
        console.log('-------------------------------------');
        setNewAddressInfo(newAddress);
        setNewAddressButtonDisabled(true);

    }


    const handleSelectedAddress = (address) => {
        // alert(address.label);
        console.log('-------------------------------------');
        console.log(address);
        console.log('-------------------------------------');
        setLocation(address.label);
        const googleAddress = address.label.split(', ').slice(0, -1).join(', '); // Extract relevant parts for Google Maps API
        console.log(googleAddress);
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${googleAddress}&key=AIzaSyA1KlSXhphzKt_F__WZzOdEE_7kFeK0I1k&libraries=places`)
        //fetch(`https://maps.googleapis.com/maps/api/place/details/json?placeid=${address.value.place_id}&key=AIzaSyA1KlSXhphzKt_F__WZzOdEE_7kFeK0I1k`)
          .then(response => response.json())
          .then(data => {
            var newAddress = {};
            if (data.results && data.results[0]) {
              console.log(data.results);
              setLatitude(data.results[0].geometry.location.lat);
              setLongitude(data.results[0].geometry.location.lng);
              handlePlaceAttributes(data.results[0]);
            //   newAddress.lat = data.results[0].geometry.location.lat;
            //   newAddress.lng = data.results[0].geometry.location.lng;
            //   setNewAddressInfo(newAddress);
            }
          })
          .catch(error => console.error(`========> ${error}`));
      };

    //   const handleChange = (event) => {
    //     console.log('-------------------------------------');
    //     console.log(event.label);
    //     console.log('-------------------------------------');
    //     setLocation(event.label);
    //   };

    // useEffect(() => {
    //     const existingScript = document.getElementById('googleMaps');
    //     if (!existingScript) {
    //         loadScript(
    //             `https://maps.googleapis.com/maps/api/js?key=AIzaSyA1KlSXhphzKt_F__WZzOdEE_7kFeK0I1k&libraries=places`,
    //             () => handleScriptLoad(setQuery, autoCompleteRef)
    //         );
    //     }
    // }, []);

    useEffect(() => {
        const loadScript = async () => {
          const apiKey = 'AIzaSyA1KlSXhphzKt_F__WZzOdEE_7kFeK0I1k'; 
          const libraries = ['places'];
    
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries}`;
          script.async = true;
          script.defer = true;
          document.body.appendChild(script);
    
          script.onload = () => {
            setLoaded(true);
          };
        };
    
        loadScript();
      }, []);

    // return (
    //     <div className="search-location-input">
    //         <input
    //             ref={autoCompleteRef}
    //             onChange={event => handleQuery(event.target.value)}
    //             placeholder="Enter your address"
    //             value={query}
    //         />
    //     </div>
    // );
    return (
        <div>
            {loaded && (
          <PlacesAutocomplete
            // apiKey="AIzaSyA1KlSXhphzKt_F__WZzOdEE_7kFeK0I1k"
            value={location}
            // onPress={(data: GooglePlaceData, details: GooglePlaceDetail = null) => {
            //     // 'details' is provided when fetchDetails = true
            //     console.log('ADDRESS PLACE API');
            //     console.log(data, details);
            // }}
            autocompletionRequest={{
                componentRestrictions: {
                country: ['us'],
                }
            }}
            selectProps={{
              placeholder: "Enter your address",
              onChange: handleSelectedAddress
            }}
          />
            )}
          {/* {latitude && longitude && (
                <div>
                <p>Selected Address: {location}</p>
                <p>Latitude: {latitude}</p>
                <p>Longitude: {longitude}</p>
                </div>
            )} */}
        </div>
    );
}

export default SearchLocationInput;