import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Chip,
    FormControlLabel,
    Radio,
    TextField,
    Button,
    CircularProgress,
    styled
} from "@material-ui/core";
import { green, grey, blueGrey, deepOrange } from '@material-ui/core/colors';
import { FaCoins } from 'react-icons/fa';
import '../styles/pages/cart.css';
import Api from '../helpers/Api';
import SnackBarNotification from './SnackBarNotification';
import { ReactComponent as Mastercard } from '../assets/images/mc_logo.svg';
import { ReactComponent as Visa } from '../assets/images/visa_logo.svg';
import { ReactComponent as Amex } from '../assets/images/amex_logo.svg';
import '../styles/components/user-credit-cards.css';
import AddCreditCard from './AddCreditCard';

const AddCardButton = styled(Button)(({ theme }) => ({
    alignSelf: 'center',
    width: '100%',
    marginTop: 30,
    color: theme.palette.getContrastText(deepOrange[800]),
    backgroundColor: deepOrange[500],
    '&:hover': {
        backgroundColor: deepOrange[800],
    },
}));

const CancelButton = styled(Button)(({ theme }) => ({
    width: '100%',
    color: theme.palette.getContrastText(grey[200]),
    marginTop: 10,
    backgroundColor: grey[300],
    '&:hover': {
        backgroundColor: blueGrey[700],
        color: theme.palette.getContrastText(grey[900]),
    },
}));

export default function UserPaymentMethods({ dialogShow,
    handleCloseDialog,
    user,
    userCards,
    setDefaultUserCard,
    setUserCards,
    establishment,
    setPickedPaymentMethod,
    setRefreshUserCards
}) {

    const [pickedCard, setPickedCard] = useState(0);
    const [newProfile, setNewProfile] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarType, setSnackbarType] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [newProfileButtonDisabled, setnewProfileButtonDisabled] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [useThisCardButtonDisabled, setUseThisCardButtonDisabled] = useState(false);

    const handleCloseDialogClean = () => {
        console.log(`handleCloseDialogClean`);
        handleCloseDialog();
    }

    function handleConfirmPickedCard() {
        setUseThisCardButtonDisabled(true);
        setIsSubmitting(true);
        try {



        } catch (err) {
            setSnackbarMessage('An error has occurred. Please, try again later.');
            setSnackbarType('error');
            setSnackbarOpen(true);
        }
        setIsSubmitting(false);
        setUseThisCardButtonDisabled(false);
    }

    async function handleAddnewProfile(status) {
        // if establishment does not accept cash and user does nor have cards added yet,
        // close the dialog
        if ('payment_methods' in establishment) {
            if (establishment.payment_methods.indexOf(1) === -1 && userCards.length === 0) {
                handleCloseDialogClean();
            }
        }
        
        setNewProfile(status);
        // setIsSubmitting(true);
        // setnewProfileButtonDisabled(true);

        // let payload = {};
        


    }

    function handleCloseAlert() {
        setSnackbarOpen(false);
        if (snackbarType === 'success') {
            handleCloseDialogClean();
        }

    }

    function handlePickedCard(card) {
        setDefaultUserCard(card);
        setPickedPaymentMethod(2);
        handleCloseDialogClean();
    }

    function handlePickedCashMethod() {
        setPickedPaymentMethod(1);
        handleCloseDialogClean();
    }

    function createNewCCProfile(values) {
        let payload = {
            app_id: establishment.establishment_fk_app_id,
            user_id: user.user_id,
            establishment_id: establishment.establishment_id,
            account: values.ccNumber.replace(/\s/g, ''),
            expiry: values.ccExpiry.replace('/', ''),
            cvv2: values.ccCvv2,
            postal: values.ccPostal,
        }
        console.log(payload);
        Api.addUserCCProfile(payload).then(res => {
            if (res.code === 201) {
                console.log(res.data);
                setUserCards([...userCards, res.data]);
                // setUserCards(res.data);
                setSnackbarMessage('New credit card added successfully');
                setSnackbarType('success');
                setSnackbarOpen(true);
                hasCardAdded(true);
                setRefreshUserCards(oldKey => oldKey +1);
                setNewProfile(false);
            } else {
                setSnackbarMessage('An error has occurred');
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        });
    }

    function hasCardAdded() {
        if (newProfile) {
            return false;

        } else if ('payment_methods' in establishment) {
            if (establishment.payment_methods !== 'undefined' && establishment.payment_methods.indexOf(1) === -1 && userCards.length === 0) {
                return false;
            } else {
                return true;
            }

        } else {
            return true;
        }
       
    }

    function hasPaymentMethod() {
        if ('payment_methods' in establishment) {
            if (establishment.payment_methods !== 'undefined' && establishment.payment_methods.indexOf(2) !== -1 && userCards.length > 0) {
                return true;
            } else {
                return false;
            }

        } else {
            return false;
        }
    }

    function showCash() {
        if ('payment_methods' in establishment) {
            if (establishment.payment_methods !== 'undefined' && establishment.payment_methods.indexOf(1) !== -1) {
                    return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }


    return (
        <Dialog
            open={dialogShow}
            onClose={handleCloseDialogClean}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onBackdropClick={handleCloseDialogClean}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title" style={{ color: '#666666' }}>
                {(hasCardAdded() === false) ? <h1>Add a new credit card</h1>
                    : <h1>Please choose a payment method and confirm</h1>}
            </DialogTitle> 
            <DialogContent>

                {(hasCardAdded() === false) ?
                    <AddCreditCard
                        createNewCCProfile={createNewCCProfile}
                        setNewProfile={handleAddnewProfile}
                    />
                    :
                    <div className='cc-info'>
                        <div className='cc-list'>
                            <h2>Payment Methods</h2>
                            <ul>
                                {hasPaymentMethod() === true &&
                                (userCards.map((item, index) => (
                                    <li key={index}>
                                        {item.accttype === 'VISA' &&
                                            <Visa className='user-card' />
                                        }
                                        {item.accttype === 'MC' &&
                                            <Mastercard className='user-card' />
                                        }
                                        {item.accttype === '????' &&
                                            <Amex className='user-card' />
                                        }
                                        <strong>**** {typeof item.token !== 'undefined' && item.token.substr(-4)}</strong>

                                        <Chip
                                            label={item.defaultacct === 'Y' ? 'Latest used' : 'Click to use'}
                                            color="primary"
                                            variant={item.defaultacct === 'Y' ? `default` : `outlined`}
                                            style={{ width: 100, width: 120 }}
                                            onClick={() => handlePickedCard(item)}
                                        />

                                    </li>
                                )))
                                }
                                {showCash() === true &&
                                <li key={3}>
                                    <div className='user-cash'>
                                        <FaCoins size={24} />
                                    </div>
                                    <strong>cash</strong>
                                    <Chip
                                        label={'Click to use'}
                                        color="primary"
                                        variant={`outlined`}
                                        style={{ width: 100, width: 120 }}
                                        onClick={() => {handlePickedCashMethod()}}
                                    />
                                </li>
}
                            </ul>
                        </div>
                        <AddCardButton onClick={() => { setNewProfile(true) }}>
                            Add a new card
                        </AddCardButton>
                        <CancelButton onClick={() => {handleCloseDialogClean()}}>
                            Cancel
                        </CancelButton>
                    </div>
                }

            </DialogContent>

            <SnackBarNotification
                handleClose={handleCloseAlert}
                open={snackbarOpen}
                vertical={`top`}
                horizontal={`center`}
                message={snackbarMessage}
                type={snackbarType}
            />

        </Dialog>
    )
}

