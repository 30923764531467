import React from "react";
import { FiTrash, FiPlus, FiMinus } from 'react-icons/fi';
import { Button, ButtonGroup } from '@material-ui/core';


const UserCartItems = ({data, handleItemQuantity, handleOpenRemoveItemDialog}) => {
    return (
        <>
        {data.map((item, index) => (
            <div key={index} className="cart-wrapper">
                
                <div className="cart-container">

                    {/* <div className="cart-item" style={{backgroundColor: 'red', }}> square 1</div>
                    <div className="cart-item" style={{backgroundColor: 'blue', }}>square 2</div>
                    <div className="cart-item" style={{backgroundColor: 'green', }}>square 3</div> */}
                    <div className="cart-item-photo">
                        {'item_image' in item 
                            ?
                            <div
                                className="item-cart-header"
                                style={{ backgroundImage: `url(${item.item_image.replaceAll(' ', '%20')})` }}>
                            </div>
                            :
                            <div
                                className="item-cart-header"
                                style={{ backgroundImage: `url("https://images.appgenie.site/2/no-image.jpg")` }}>
                            </div>
                        }
                        <div className="item-amount">
                        <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                            <Button variant="contained" color="default" onClick={() => handleItemQuantity(item.item_id, item.item_name, item.item_quantity, '-')} size="small">
                                <FiMinus size={22} color="#000" />
                            </Button>
                            <Button disabled={true} style={{ color: '#000', fontSize: 16 }} size="small">{item.item_quantity}</Button>
                            <Button variant="contained" color="default" onClick={() => handleItemQuantity(item.item_id, item.item_name, item.item_quantity, '+')} size="small">
                                <FiPlus size={22} color="#000" />
                            </Button>
                        </ButtonGroup>
                        </div>
                    </div>
                    
                    <div className="cart-item">
                        <h3>{item.item_name}</h3>
                        {item.modifiers.length > 0 &&
                            <div className="options">
                                <p className="options_name">Options</p>
                                {item.modifiers.map(modifier => (
                                    <div key={modifier.modifier_id} className="options_items">
                                        <p>
                                            {modifier.modifier_name}
                                            {modifier.sub_modifiers && modifier.sub_modifiers.items_modifier
                                                && modifier.sub_modifiers.items_modifier.map(sub => (
                                                    (sub.modifier_checked === true &&
                                                        <span style={{ paddingLeft: 10, display: 'block', clear: 'both' }}>- {sub.modifier_name}</span>
                                                    )
                                                ))}

                                        </p>
                                        <p>{modifier.modifier_price === 0 ? `` : `$ ${modifier.modifier_price.toFixed(2)}`}</p>
                                    </div>
                                ))}
                            </div>
                        }

                    </div>

                    <div className="cart-item" style={{textAlign: 'right'}}>
                        $ {(item.item_price * item.item_quantity).toFixed(2)}
                        <FiTrash
                            color='#cfc0c0'
                            style={{ marginLeft: 10, cursor: 'pointer' }}
                            onClick={() => handleOpenRemoveItemDialog(item.item_name, item.item_id)}
                        />
                    </div>
                </div>
            </div>
        
        ))}
        </>
    );
}

export default UserCartItems;