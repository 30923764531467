import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from '../components/Loading';
import { Sidebar } from '../components/Sidebar';
import ShopDialog from '../components/ShopDialog';
import { ShopHeader } from '../components/ShopHeader';
import '../styles/pages/general.css';
import '../styles/pages/category-items.css';
import api from '../services/api';
import { shoppingCartTotalItems } from '../helpers/functions';

export function CategoryItems() {
    const [menuItems, setMenuItems] = useState([]);
    const [dialogShow, setDialogShow] = useState(false);
    const [detailItem, setDetailItem] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);
    const { category_id } = useParams();

    useEffect(() => {
        api.get(`/menu/menu-category-items/${category_id}`).then(response => {
            setMenuItems(response.data.data)
        });

        let total = shoppingCartTotalItems();
        setTotalItems(total);

    }, [category_id], [totalItems], [detailItem], [menuItems]);

    function handleShopDialog(selected) {
        if (selected.length === 0) {
            setLoading(true);
            setDialogShow(true);
        } else {
            console.log(selected);
            setDetailItem(selected);
            setLoading(false);
        }
    }


    function handleOpenProductDetails(item_id, item_sold_out) {
        if (item_sold_out === 1) {
            return;
        }
        handleShopDialog([]);
        try {
            api.get(`/menu/menu-item/${item_id}`)
                .then(response => handleShopDialog(response.data.data));
           
        } catch (error) {
            console.log(error);
            return
        }
    }

    function handleClose() {
        setDialogShow(false);
    }

    if (menuItems.length === 0) {
        return <Loading />
    }

    return (
        <div className="container-wrapper">
            <div className="sidebar">
                <Sidebar />
            </div> 
            <div className="content-wrapper">

                <ShopHeader 
                    title={menuItems[0].category}
                    totalItems={totalItems}
                    categoryLink={true}
                />

                <ShopDialog 
                    dialogShow={dialogShow}
                    handleCloseDialog={handleClose}
                    selected={detailItem}
                    loading={loading}
                    setTotalItems={setTotalItems}
                    totalItems={totalItems}
                />

                <div className="content-items">
                    {menuItems.map(item => {
                        return (
                            <div key={item.item_id} onClick={() => handleOpenProductDetails(item.item_id, item.item_sold_out)} className="container-item">
                                {'item_image' in item ?
                                    <div 
                                        style={{backgroundImage: `url(${item.item_image.replaceAll(' ', '%20')})`}}  
                                        className="option-image">
                                    </div>
                                :
                                    <div 
                                        style={{ backgroundImage: `url("https://images.appgenie.site/2/no-image.jpg")` }}
                                        className="option-image">
                                    </div>
                                }

                                <div className="item-info">
                                    <div className="item-header">
                                        <p>
                                            {item.item_name}<br />
                                            <span className="item-description">
                                                {item.item_description}
                                            </span>
                                        </p>
                                        <strong>$ {item.item_price.toFixed(2)}</strong>
                                    </div>
                                    <div className="item-footer">
                                        {item.item_sold_out == 0 ?
                                        <span>Add to cart</span>
                                        :
                                        <span style={{backgroundColor: '#000000', color: '#ffffff', padding: 5}}>SOLD OUT</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>
        </div>
    )
}