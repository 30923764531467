import React, { useState, useEffect } from 'react';
import { 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    Button, 
    ButtonGroup,
    FormControl,
    FormLabel, 
    RadioGroup, 
    FormControlLabel, 
    Radio, 
    List, 
    ListItem, 
    FormGroup, 
    Checkbox, 
    Accordion, 
    AccordionSummary, 
    AccordionDetails,
    TextField } from '@material-ui/core';
import { blue, grey } from '@material-ui/core/colors';
import { FiPlus, FiMinus } from 'react-icons/fi';
import '../styles/components/shop-dialog.css';
import SnackBarNotification from './SnackBarNotification';

// Eurogrill > BBQ > BBQ Combo 2

export default function ShopDialog({dialogShow, handleCloseDialog, selected, loading, setTotalItems}) {

    const [qty, setQty] = useState(1);
    const [enabledGroups, setEnabledGroups] = useState([]);
    const [activeGroup, setActiveGroup] = useState('');
    const [modifiers, setModifiers] = useState([]);
    const [submodifiers, setSubmodifiers] = useState([]);
    const [customMessage, setCustomMessage] = useState('');
    const [expanded, setExpanded] = useState('');
    const [specialInstructions, setSpecialInstructions] = useState('');
    const [modifiersLimit, setModifiersLimit] = useState(false);
    const [submodifiersLimit, setSubmodifiersLimit] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarType, setSnackbarType] = useState(false);
    const [adding, setAdding] = useState(false);

    useEffect(() => {
        setSpecialInstructions('');
        setAdding(false);
    }, [submodifiers], [adding]);

    function handleFixedDecimal(price) {
        try {
            return price.toFixed(2);
        } catch(err) {
            return parseFloat(0).toFixed(2);
        }
    }

    function handleQtyIncrease() {
        setQty(qty => qty + 1)
    }
    function handleQtyDecrease() {
        var more = qty;

        if (qty === 1) {
            more = 1;
            setQty(more)
        } else {
            setQty(qty => qty - 1)
        }
    }

    function handleEnableRadioOptions(modifier, block_id) {
        
        setSubmodifiersLimit(false);
        setSubmodifiers([]);

        selected.modifiers.forEach(function(item){

            if (item.menu_modifier_id === modifier.menu_modifier_id) {

                item.modifier_checked = true;
                let min = item.menu_modifier_min;
                let max = item.menu_modifier_max;

                if (item.items_modifier) {
                    item.items_modifier.forEach(function(mod){
                        if (mod.modifier_id === modifier.modifier_id) {
                            mod.modifier_checked = true;
                        
                        } else {

                            if (min === 1 && max === 1) {
                                mod.modifier_checked = false;
                            }
                        }
                    });
                }
            }
        });

        setExpanded(modifier.sub_modifiers ? block_id : 0);
        
    }

    function handleSubmodifiers(event, sub_id, mod_id, item_id) {

        var submodifiersCollection = [];
        selected.modifiers.forEach(function(item){
            if(item.menu_modifier_id === item_id) {

                item.items_modifier.forEach(function(mod){
                    if(mod.modifier_id === mod_id) {
                        // let min = mod.sub_modifiers.menu_modifier_min;
                        let max = mod.sub_modifiers.menu_modifier_max;
                        let counter = 0;

                        mod.sub_modifiers.items_modifier.forEach(function(sub){
                            if(sub.modifier_id === sub_id){
                                sub.modifier_checked = event.target.checked;
                            }

                            if (sub.modifier_checked) {
                                counter++;
                                if (counter <= max) {
                                    submodifiersCollection.push(sub.modifier_id);
                                }
                            } 
                            
                        });
                        setSubmodifiers(submodifiersCollection);
                        
                        if (counter === max) {
                            
                            setSubmodifiersLimit(true);
                            setCustomMessage('Your options limit has been reached');
                            setSnackbarType('info');
                            setSnackbarOpen(true);

                        } else if (counter > max) {

                            setSubmodifiersLimit(true);
                            setCustomMessage('Please disable one option before change');
                            setSnackbarType('warning');
                            setSnackbarOpen(true);

                        } else {
                            setSubmodifiersLimit(false);
                        }
                    }
                    // } else {

                    //     console.log('...');
                    //     mod.modifier_checked = false;
                    //     if (mod.sub_modifiers) {
                    //         mod.sub_modifiers.items_modifier.forEach(function(sub){
                    //             sub.modifier_checked = false;
                    //         });
                    //     }
                    // }
                });
            } 
        });

    }

    function handleOrderValidation() {
        setAdding(true);
        try {
            var validated = true;

            selected.quantity = qty;
            selected.modifiers && selected.modifiers.forEach(function(item){
                
                let min = item.menu_modifier_min ? item.menu_modifier_min : 0;
                let max = item.menu_modifier_max ? item.menu_modifier_max : 0;
                let count = 0;

                item.items_modifier && item.items_modifier.forEach(function(mod){
                    if (mod.modifier_checked) {
                        count++;

                        if (mod.sub_modifiers) {

                            let sub_min = mod.sub_modifiers.menu_modifier_min;
                            let sub_max = mod.sub_modifiers.menu_modifier_max;
                            let sub_count = 0;

                            mod.sub_modifiers.items_modifier.forEach(function(sub){
                                if (sub.modifier_checked) {
                                    sub_count++;
                                }
                            });
                            
                            if (sub_count < sub_min || sub_count > sub_max) {
                                setCustomMessage(`${mod.modifier_name} - select between ${sub_min} and ${sub_max} option(s)`);
                                setSnackbarType('error');
                                setSnackbarOpen(true);
                                validated = false;
                                setAdding(false);
                                return;
                            }
                        }
                        
                    }
                });

                // radio - only one choice allowed
                if (min === 1 && max === 1 && count === 0) {
                    setCustomMessage(`${item.menu_modifier_name} - select at least ${min} option(s)`);
                    setSnackbarType('error');
                    setSnackbarOpen(true);
                    validated = false;
                    setAdding(false);
                    return;
                }

                // select - multiple choices allowed
                if (count < min || count > max) {
                    setCustomMessage(`${item.menu_modifier_name} - select between ${min} and ${max} option(s)`);
                    setSnackbarType('error');
                    setSnackbarOpen(true);
                    validated = false;
                    setAdding(false);
                    return;
                } 
                
            });
            
            if (validated) {
                setCustomMessage(`Order successfully added`);
                setSnackbarType('success');
                setSnackbarOpen(true);
                // update basket badger
                setTotalItems(totalItems => totalItems + qty);
                saveDataToLocalStorage(selected);
                return;
            }

        } catch(err) {  
            console.log(err);
            setCustomMessage(`An error has occurred. Please try again later.`);
            setSnackbarType('error');
            setSnackbarOpen(true);
            setAdding(false);
        } 

    }

    function handleCloseDialogClean() {
        handleExpandedOption('');
        setExpanded('');
        handleCloseDialog();
        setSpecialInstructions('');
        setModifiers([]);
        setSubmodifiers([]);
        setQty(1);
        setEnabledGroups([]);
        setModifiersLimit(0);
        setSubmodifiersLimit(0);
        // setRangeOptions([]);
    }

    function handleCloseAlert() {
        setSnackbarOpen(false);
        if (snackbarType === 'success') {
            handleCloseDialogClean();
        }
    }

    const handleExpandedOption = (subitem, item_id) => (event, newExpanded) => {

        setSubmodifiersLimit(false);
        if (subitem.sub_modifiers) {
            let picked_id = `${subitem.menu_modifier_id}/${subitem.modifier_id}`;
            setExpanded(newExpanded ? picked_id : false);
        }

        setActiveGroup(item_id);

        var modifiersCollection = [];
        selected.modifiers.forEach(function(item){

            let mod_id = subitem.modifier_id;
            // let min = item.menu_modifier_min;
            let max = item.menu_modifier_max;
            let counter = 0;

            if (item.menu_modifier_id === item_id && item.items_modifier) {
                item.modifier_checked = event.target.checked;
                item.items_modifier.forEach(function(mod){
                    if (mod.modifier_id === mod_id) {
                        mod.modifier_checked = event.target.checked;
                    }
                    if (mod.modifier_checked) {
                        modifiersCollection.push(mod.modifier_id);
                        counter++;
                    }
                    if (counter === max) {

                        setModifiersLimit(true);
                        setCustomMessage('Your options limit has been reached');
                        setSnackbarType('info');
                        setSnackbarOpen(true);
                        return;

                    } else if (counter > max) {

                        setModifiersLimit(true);
                        setCustomMessage('Please disable one option before change');
                        setSnackbarType('warning');
                        setSnackbarOpen(true);
                        return;

                    } else {
                        setModifiersLimit(false);
                    }
                });
            }

        });

        setModifiers(modifiersCollection);

    }

    const handleSpecialInstructions = (event) => {
        let text = event.target.value;
        if (text.length < 41) {
            setSpecialInstructions(text);
        }
    };

    function saveDataToLocalStorage(data) {
        try {
            if (typeof data.modifiers !== 'undefined') {
                data.modifiers.forEach(function(sub){
                    if (typeof sub.items_modifier !== 'undefined') {
                        sub.items_modifier.forEach(function(subitem){
                            if(typeof subitem.modifier_checked === 'undefined') {
                                subitem.modifier_checked = false;
                            }
                        });
                    }
                });
            }

        } catch(err) {
            console.log(err);
        }

        data.special_instructions = null;
        if (specialInstructions) {
            data.special_instructions = specialInstructions;
        }
        var a = JSON.parse(localStorage.getItem('@appgenie/items')) || false;
        const establishment = JSON.parse(localStorage.getItem('@appgenie/establishment')) || false;
        if (a !== false) {
            if (a.app_id === establishment.establishment_fk_app_id) {
                a.items.push(data);
                localStorage.setItem('@appgenie/items', JSON.stringify(a));
            } else {
                var new_items = {app_id: establishment.establishment_fk_app_id, items: [data]}
                localStorage.setItem('@appgenie/items', JSON.stringify(new_items));
            }
        } else {
            var new_items = {app_id: establishment.establishment_fk_app_id, items: [data]}
            localStorage.setItem('@appgenie/items', JSON.stringify(new_items));
        }
        
    }

    return (
        <Dialog
            open={dialogShow}
            onClose={handleCloseDialogClean}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onBackdropClick={handleCloseDialogClean}
            fullWidth
            maxWidth="sm"
        >
            {/* <DialogTitle id="alert-dialog-title" style={{color: '#666666'}}>
                {"Please choose the desired quantity and confirm"}
                </DialogTitle> */}
                <DialogContent>
                    {!loading &&
                    <DialogContentText id="alert-dialog-description">
                        <div 
                            style={{backgroundImage: `url(${selected.item_image.replaceAll(' ', '%20')})`}} 
                            class='main-image'>
                        </div>
                        
                        <h3>{selected.item_name}</h3>
                        <h2 style={{color: 'black', fontSize: 28}}>$ {handleFixedDecimal(selected.item_price)}</h2>
                        {selected.modifiers && selected.modifiers.map(item => 
                            // only one option is allowed, so let's radiobutton-it
                            (item.menu_modifier_max === 1 && item.menu_modifier_min === 1 ?
                            <FormControl component="fieldset" class="form-block">
                                    <FormLabel 
                                        component="legend" 
                                        style={{fontWeight: 'bold'}}
                                    >
                                        {item.menu_modifier_name}
                                        <h5 style={{color: '#ccc'}}>Select at least one option</h5>
                                    </FormLabel>
                            
                                    <RadioGroup
                                        aria-label="modifier"
                                        defaultValue={item.items_modifier[0].modifier_id}
                                        name="radio-buttons-group"
                                    >
                                        <List>
                                        {item.items_modifier.map(subitem => (
                                            <Accordion
                                                expanded={expanded === `${subitem.menu_modifier_id}/${subitem.modifier_id}`}
                                                // onChange={handleChange(subitem.modifier_id)}
                                            >
                                                <AccordionSummary
                                                    aria-controls={`panel${subitem.modifier_id}-content`}
                                                    id={`panel${subitem.modifier_id}-header`}
                                                >
                                                    <ListItem>
                                                        <FormControlLabel 
                                                            value={subitem.modifier_id.toString()}
                                                            control={<Radio onChange={() => handleEnableRadioOptions(subitem, `${subitem.menu_modifier_id}/${subitem.modifier_id}`)} />} 
                                                            label={subitem.modifier_price > 0 ? 
                                                                subitem.modifier_name + ' - $ ' + subitem.modifier_price.toFixed(2) 
                                                                : subitem.modifier_name} />
                                                    </ListItem>
                                                </AccordionSummary>
                                                {subitem.sub_modifiers && 
                                                <AccordionDetails>
                                                    <FormGroup 
                                                        style={{marginLeft: 70}}
                                                        id={item.items_modifier[0].modifier_id}
                                                    >
                                                        <FormLabel 
                                                            component="legend" 
                                                            style={{fontWeight: 'bold', paddingBottom: 10}}
                                                        >
                                                            {subitem.sub_modifiers.menu_modifier_min > 0 && `Select up to ${subitem.sub_modifiers.menu_modifier_max} options 
                                                            (minimum of ${subitem.sub_modifiers.menu_modifier_min} option is required)`}

                                                            {subitem.sub_modifiers.menu_modifier_min === 0 && `Select up to ${subitem.sub_modifiers.menu_modifier_max} options (not required)`}
                                                            
                                                        </FormLabel>
                                                    {subitem.sub_modifiers.items_modifier.map(option => (
                                                            <FormControlLabel 
                                                                disabled={submodifiersLimit && submodifiers.indexOf(option.modifier_id) === -1}
                                                                checked={submodifiers.indexOf(option.modifier_id) !== -1}
                                                                control={<Checkbox />}
                                                                label={`${option.modifier_name}`}
                                                                // onChange={(event) => handleRangeOptions(event, option.modifier_id)}
                                                                onChange={(event) => handleSubmodifiers(event, option.modifier_id, subitem.modifier_id, subitem.menu_modifier_id)}
                                                            />
                                                        )
                                                    )}
                                                    </FormGroup>
                                                </AccordionDetails>
                                                
                                                }
                                            </Accordion>
                                            
                                            )
                                        )}
                                        </List>
                                    </RadioGroup>
                            </FormControl>
                            // Otherwise, use checkbox instead
                            :
                            <FormControl component="fieldset" class="form-block">
                                    <FormLabel 
                                        component="legend" 
                                        style={{fontWeight: 'bold'}}
                                    >
                                        {item.menu_modifier_name}
                                        {item.menu_modifier_min > 0 ?
                                        <h5 style={{color: '#ccc'}}>Select at least {item.menu_modifier_min} option(s) (max {item.menu_modifier_max} options)</h5>
                                        :
                                        <h5 style={{color: '#ccc'}}>Select up to {item.menu_modifier_max} option(s) (not required)</h5>
                                        }
                                    </FormLabel>
                                    <List>
                                    {item.items_modifier.map(subitem => (
                                        <Accordion
                                            expanded={expanded === `${subitem.menu_modifier_id}/${subitem.modifier_id}`}
                                            // onChange={handleChange(subitem.modifier_id)}
                                        >
                                            <AccordionSummary
                                                aria-controls={`panel${subitem.modifier_id}-content`}
                                                id={`panel${subitem.modifier_id}-header`}
                                            >
                                            <FormControlLabel 
                                                disabled={modifiersLimit && modifiers.indexOf(subitem.modifier_id) === -1 && activeGroup === item.menu_modifier_id}
                                                control={<Checkbox />}
                                                label={subitem.modifier_name}
                                                onChange={handleExpandedOption(subitem, item.menu_modifier_id)}
                                            />
                                            </AccordionSummary>
                                            {subitem.sub_modifiers && 
                                                <AccordionDetails>
                                                <FormGroup 
                                                    style={{marginLeft: 70}}
                                                    id={item.items_modifier[0].modifier_id}
                                                >
                                                    <FormLabel 
                                                        component="legend" 
                                                        style={{fontWeight: 'bold', paddingBottom: 10}}
                                                    >
                                                        {subitem.sub_modifiers.menu_modifier_min > 0 && `Select up to ${subitem.sub_modifiers.menu_modifier_max} options 
                                                          (minimum of ${subitem.sub_modifiers.menu_modifier_min} option is required)`}

                                                        {subitem.sub_modifiers.menu_modifier_min === 0 && `Select up to ${subitem.sub_modifiers.menu_modifier_max} options (not required)`}
                                                        
                                                    </FormLabel>
                                                {subitem.sub_modifiers.items_modifier.map((option, index3) => (
                                                        <FormControlLabel 
                                                            key={index3}
                                                            disabled={submodifiersLimit && submodifiers.indexOf(option.modifier_id) === -1}
                                                            control={<Checkbox />}
                                                            label={option.modifier_name}
                                                            // onChange={(event) => handleRangeOptions(event, option.menu_modifier_id, subitem.modifier_id)}
                                                            onChange={(event) => handleSubmodifiers(event, option.modifier_id, subitem.modifier_id, subitem.menu_modifier_id)}
                                                        />
                                                    )
                                                )}
                                                </FormGroup>
                                                </AccordionDetails>
                                            }
                                        
                                        </Accordion>
                                    ))}
                                    </List>
                                    
                            </FormControl>
                            ))
                        }
                        <TextField
                            id="outlined-helperText"
                            label="Special instructions"
                            value={specialInstructions}
                            helperText="Type up to 40 characters"
                            onChange={handleSpecialInstructions}
                            fullWidth
                        />
                    </DialogContentText>
                    }
                    {loading &&
                        <DialogContentText id="alert-dialog-description">
                            <h3>Loading...</h3>
                        </DialogContentText>
                    }
                    
                </DialogContent>
                {!adding &&
                <DialogActions>
                    <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                        <Button
                            style={{ backgroundColor: grey[200], color: 'white' }}
                            onClick={handleQtyDecrease}>
                            <FiMinus size={22} color="#000" />
                        </Button>
                        <Button disabled='disabled' style={{color: '#000', fontSize: 16}}>{qty}</Button>
                        <Button
                            style={{ backgroundColor: grey[200], color: 'white' }}
                            onClick={handleQtyIncrease}>
                            <FiPlus size={22} color="#000" />
                        </Button>
                    </ButtonGroup>
                        <Button 
                            style={{ backgroundColor: grey[200], color: '#666', width: 100 }}
                            onClick={handleCloseDialogClean}>
                                Close
                        </Button>
                        <Button 
                            style={{ backgroundColor: blue[800], color: 'white', width: 100 }}
                            onClick={handleOrderValidation} autoFocus>
                                Confirm
                        </Button>
                </DialogActions>
                }

                <SnackBarNotification 
                    handleClose={handleCloseAlert}
                    open={snackbarOpen}
                    vertical={`bottom`}
                    horizontal={`center`}
                    message={customMessage}
                    type={snackbarType}
                    
                />
        </Dialog>
    );
}