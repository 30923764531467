import React, { useEffect, useState } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Loading } from '../components/Loading';
import { Sidebar } from '../components/Sidebar';

import '../styles/pages/all-apps.css'

import api from '../services/api';

export function AllApps() {
    const [data, setData] = useState([]);

    useEffect(() => {
        async function getAllApps() {
            try {
                await api.get('/app/get-all-apps/').then((response) => {
                    setData(response.data.data);
                    return;
                })
            } catch (error) {
                return console.log(error)
            }
        }
        getAllApps();
    }, []);

    if (data.length === 0) {
        return <Loading />
    }

    function handlePickedEstablishment (app_url, app_id) {
        localStorage.setItem('@appgenie/app_url', app_url);
        localStorage.setItem('@appgenie/appId', app_id);
    }

    return (
        <div className="container-all-apps">
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="content-apps">
                <h2>Select establishment</h2>
                {data.map(item => (
                    <Link
                        key={item.app_id}
                        className="buttonApps"
                        to={`/app/${item.app_url}`}
                        onClick={() => handlePickedEstablishment(item.app_url, item.app_id)}
                    >
                        <p>{item.app_name}</p>
                        <FiArrowRight size={24} color="#000" />
                    </Link>
                ))}
            </div>
        </div>
    )
}