import React, { useState, useEffect } from 'react';
import { FiTrash } from 'react-icons/fi';
import { Button, styled } from '@material-ui/core';
import { green, deepOrange, orange } from '@material-ui/core/colors';
import { ShopHeader } from '../components/ShopHeader';
import { Sidebar } from '../components/Sidebar';
import { shoppingCartTotalItems } from '../helpers/functions';
import '../styles/pages/profile.css';
import { ReactComponent as Mastercard } from '../assets/images/mc_logo.svg';
import { ReactComponent as Visa } from '../assets/images/visa_logo.svg';
import { Loading } from '../components/Loading';
import Api from '../helpers/Api';
import ConfirmationDialog from '../components/ConfirmationDialog';
import SnackBarNotification from '../components/SnackBarNotification';
import AddCreditCard from '../components/AddCreditCard';

const AddCardButton = styled(Button)(({ theme }) => ({
    alignSelf: 'center',
    width: '50%',
    color: theme.palette.getContrastText(deepOrange[800]),
    marginTop: 20,
    backgroundColor: deepOrange[500],
    '&:hover': {
      backgroundColor: deepOrange[800],
    },
}));

const user = JSON.parse(localStorage.getItem('@appgenie/user')) || false;
const establishment = JSON.parse(localStorage.getItem('@appgenie/establishment')) || false;

export function Profile() {

    const [totalItems, setTotalItems] = useState(0);
    const [userInfo, setUserInfo] = useState({});
    const [userCards, setUserCards] = useState([]);
    const [removeDialogStatus, setRemoveDialogStatus] = useState(false);
    const [removeDialogTitle, setRemoveDialogTitle] = useState('');
    const [removeCard, setRemoveCard] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('');
    const [newProfile, setNewProfile] = useState(false);

    useEffect(() => {
        let total = shoppingCartTotalItems;
        setTotalItems(total);
        async function getUserInfo() {
            let user = await JSON.parse(localStorage.getItem('@appgenie/user'));
            if (typeof user.user_name !== 'undefined') {
                setUserInfo(user);
            } 
        }
        getUserInfo();

        const getUserCCProfile = async () => {

            if (typeof user.cc_profiles !== 'undefined') {

                try {
                
                    await user.cc_profiles.forEach(function(item) {
                        let payload = {
                            cc_profile_id: item.cc_profile_id,
                            establishment_id: item.cc_profile_fk_establishment_id,
                        }
                        Api.getUserCCProfile(payload)
                        .then(res => {
                            if (res.code === 201) {
                                // console.log(res);
                                setUserCards(res.data);
                            }
                        });
                        
                    });
        
                } catch(err) {
                    console.log(err);
                }

            }
        }

        setUserCards([]);
        getUserCCProfile();

    }, [totalItems], [userCards], [userInfo]);

    function handleRemoveDialogStatus() {
        setRemoveDialogStatus(false);
    }

    function handleConfirmRemoveItem() {
        let payload = {
            app_id: establishment.establishment_fk_app_id,
            cc_profile_id: removeCard.profileid,
            account: removeCard.token,
            acctid: removeCard.acctid,
        }
        let res = Api.deleteUserCCProfile(payload);
        if (res.code === 201) {
            setSnackbarMessage('Your card was deleted successfully');
            setSnackbarType('success');
            setSnackbarOpen(true);
        } else {
            setSnackbarMessage('An error has occurred');
            setSnackbarType('error');
            setSnackbarOpen(true);
        }
    }

    function handleCloseRemoveDialog() {
        setRemoveCard({});
        setRemoveDialogTitle('');
    }

    function handleOpenRemoveItemDialog(card) {
        let cardName = card.accttype === 'MC' ? 'Mastercard' : 'Visa'
        setRemoveCard(card);
        setRemoveDialogTitle(`Remove your ${cardName} card number **** ${card.token.substr(-4)}?`);
        setRemoveDialogStatus(true);
    }
 
    function handleCloseSnackbar() {
        setSnackbarOpen(false);
    }

    function createNewCCProfile (values) {
        let payload = {
            app_id: establishment.establishment_fk_app_id,
            user_id: user.user_id,
            establishment_id: establishment.establishment_id,
            account: values.ccNumber.replace(/\s/g, ''),
            expiry: values.ccExpiry.replace('/', ''),
            cvv2: values.ccCvv2,
            postal: values.ccPostal,
        } 
        console.log(payload);
        Api.addUserCCProfile(payload).then(res => {
            if (res.code === 201) {
                console.log(res.data);
                setUserCards([...userCards, res.data]); 
                // setUserCards(res.data);
                setSnackbarMessage('New credit card added successfully');
                setSnackbarType('success');
                setSnackbarOpen(true);
            } else {
                setSnackbarMessage('An error has occurred');
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        });
    }

    return (
        <div className="container-wrapper">
            <ConfirmationDialog
                open={removeDialogStatus}
                titleText={removeDialogTitle}
                bodyText={`Upon confirmation, the card will be removed.`}
                cancelAction={handleRemoveDialogStatus}
                confirmAction={handleConfirmRemoveItem}
                handleClose={handleCloseRemoveDialog}
            />
            <div className="sidebar">
                <Sidebar goHome={true} />
            </div> 
            <div className="content-wrapper">

                <ShopHeader 
                    title={`Profile`}
                    totalItems={totalItems}
                />
                <div className="content-items-profile">
                    {typeof userInfo.user_name === 'undefined' ?
                        <Loading />
                    :
                        <div className='user-info'>
                            <div className='user-info-line'>
                                <strong>Name:</strong>
                                <span>{userInfo.user_name}</span>
                            </div>
                            <div className='user-info-line'>
                                <strong>E-mail:</strong>
                                <span>{userInfo.user_email}</span>
                            </div>
                            <div className='user-info-line'>
                                <strong>Phone:</strong>
                                <span>{userInfo.user_phone}</span>
                            </div>
                        </div>
                    }

                    {newProfile ?
                        <AddCreditCard 
                            createNewCCProfile={createNewCCProfile}
                            setNewProfile={setNewProfile}
                        />
                    :
                        <div className='cc-info'>
                            {typeof user.cc_profiles !== 'undefined' && userCards.length === 0 ?
                            <Loading />
                            :
                            <div className='cc-list'>
                                <h2>Payment Methods</h2>
                                <ul>
                                    {userCards.map((item, index) => (
                                        <li key={index}>
                                            {item.accttype === 'VISA' &&
                                                <Visa className='user-card' /> 
                                            }
                                            {item.accttype === 'MC' &&
                                                <Mastercard className='user-card' /> 
                                            }
                                            <strong>**** {typeof item.token !== 'undefined' && item.token.substr(-4)}</strong>
                                            <FiTrash 
                                                size={15} 
                                                color="#666" 
                                                style={{cursor: 'pointer'}}
                                                onClick={() => handleOpenRemoveItemDialog(item)} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            }
                            <AddCardButton onClick={() => {setNewProfile(true)}}>
                                Add a new card
                            </AddCardButton>
                        </div>
                    }
                </div>
            </div>
            <SnackBarNotification 
                handleClose={handleCloseSnackbar}
                open={snackbarOpen}
                vertical={`bottom`}
                horizontal={`right`}
                message={snackbarMessage}
                type={snackbarType}
            />
        </div>
    )
}