import React, { useState, useRef, useEffect } from 'react';
import { Sidebar } from '../components/Sidebar';
import { ShopHeader } from '../components/ShopHeader';
import '../styles/pages/general.css';
import '../styles/pages/signin.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Box, Button, CircularProgress } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { green, blueGrey, grey, lightBlue } from '@material-ui/core/colors';
import { styled } from '@material-ui/core';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, FacebookAuthProvider, OAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { FaApple, FaFacebook } from 'react-icons/fa';
import { FIREBASE } from '../helpers/config';
import Api from '../helpers/Api'; 
import SnackBarNotification from '../components/SnackBarNotification';

// https://medium.com/identity-beyond-borders/how-to-configure-sign-in-with-apple-77c61e336003

const SigninButton = styled(Button)(({ theme }) => ({
    width: '100%',
    color: theme.palette.getContrastText(green[800]),
    marginTop: 20,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[800],
    },
}));

const SignupButton = styled(Button)(({ theme }) => ({
    width: '100%',
    color: theme.palette.getContrastText(grey[200]),
    marginTop: 20,
    backgroundColor: grey[300],
    '&:hover': {
        backgroundColor: blueGrey[700],
        color: theme.palette.getContrastText(grey[900]),
    },
}));

const FacebookButton = styled(Button)(({ theme }) => ({
    width: '100%',
    color: theme.palette.getContrastText(lightBlue[900]),
    marginTop: 20,
    backgroundColor: '#4267b2',
    '&:hover': {
        backgroundColor: lightBlue[700],
        color: theme.palette.getContrastText(lightBlue[800]),
    },
}));

const AppleButton = styled(Button)(({ theme }) => ({
    width: '100%',
    color: '#fff',
    marginTop: 20,
    backgroundColor: '#000000',
    '&:hover': {
        backgroundColor: grey[700],
    },
}));

export function SignIn() {

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('');
    const [signingIn, setSigningIn] = useState(false);

    const history = useHistory();

    useEffect(() => {
        const userIsLoggedIn = () => {
            var user = JSON.parse(localStorage.getItem('@appgenie/user')) || false;
    
            if (user) {
                if (user.user_verified === 0 || user.user_verified === false) {
                    history.push('/verify-account');
                } else {

                    var now = new Date().getTime();
                    var loginTime = user.loginTime;
                    if (now-loginTime < 24*60*60*1000) {
                        history.push('/profile');
                    } 

                }
            }
        } 
        userIsLoggedIn();
    }, []);

    const app = initializeApp(FIREBASE);
    const analytics = getAnalytics(app);
    
    const auth = getAuth();
    auth.useDeviceLanguage();

    function handleFacebookSignin() {

        const providerFB = new FacebookAuthProvider();

        signInWithPopup(auth, providerFB)
        .then((result) => {
            const user = result.user;
            var establishment = JSON.parse(localStorage.getItem('@appgenie/establishment')) || [];

            var payload = {
                'user_name': user.displayName,
                'user_email': user.email,
                'profile_id': user.uid,
                'social_media': 'facebook',
                'app_id': establishment.establishment_fk_app_id,
                'user_profile_image': user.photoURL
            }

            signInUser(payload);

            const credential = FacebookAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;

        })
        .catch((error) => {
            console.log(error);
            const errorCode = error.code;
            console.log(error.code);
            console.log(error.message);
            console.log(error.email);
            const errorMessage = error.message;
            // The email of the user's account used
            const email = error.email;
            // The AuthCredential type that was used
            const credential = FacebookAuthProvider.credentialFromError(error);

        });

    }

    function handleAppleSignin() {

        const providerApple = new OAuthProvider('apple.com');

        signInWithPopup(auth, providerApple)
        .then((result) => {
            const user = result.user;
            // console.log(user);

            var establishment = JSON.parse(localStorage.getItem('@appgenie/establishment')) || [];

            var payload = {
                'user_name': user.displayName,
                'user_email': user.email,
                'profile_id': user.uid,
                'social_media': 'facebook',
                'app_id': establishment.establishment_fk_app_id,
                'user_profile_image': user.photoURL
            }

            signInUser(payload);

            // Apple credential
            const credential = OAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;
            const idToken = credential.idToken;

        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used
            const email = error.email;
            // The credential that was used
            const credential = OAuthProvider.credentialFromError(error);

        });
    }

    const signInUser = async (payload) => {

        let res = await Api.socialSignIn(payload);
        console.log('sending request');
        console.log(res);

        if (res.code === 201) {
            setUserInfo(res);
        }

    }

    function setUserInfo(res) {

        res.data.loginTime = new Date().getTime();
        localStorage.setItem('@appgenie/user', JSON.stringify(res.data));

        if (res.data.user_verified === 0 || res.data.user_verified === false) {
            history.push('/verify-account');
        } else {
            var redir = localStorage.getItem('@appgenie/redir') || false;
            if (redir) {
                localStorage.removeItem('@appgenie/redir');
                history.push(redir);
            } else {
                let app_url = localStorage.getItem('@appgenie/app_url') || false;
                history.push(`/app/${app_url}`);
            }
            
        }

        /* @TODO
        var hours = 24; // Reset when storage is more than 24hours
        var now = new Date().getTime();
        var setupTime = localStorage.getItem('loginTime');
        if(now-setupTime > hours*60*60*1000) {
            localStorage.clear()
        }*/
    }

    const handleOpenSignup = () => {
        history.push('/signup');
    }

    const password = useRef(null);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
        password: Yup.string()
          .min(2, 'Too Short!')
          .max(10, 'Too Long!')
          .required('Required')
    });

    const {
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        errors,
        touched
      } = useFormik({
        validationSchema: LoginSchema,
        initialValues: { email: '', password: '' },
        onSubmit: values => {
            login(values.email, values.password);
        }
         
    });

    async function login(email, password) {
        setSigningIn(true);
        try {
            const establishment = JSON.parse(localStorage.getItem('@appgenie/establishment')) || [];
            let payload = {
                user_email: values.email,
                user_password: values.password,
                app_id: establishment.establishment_fk_app_id,
            }
            var res = await Api.signIn(payload);

            if (res.code === 201) {
                setSnackbarMessage('You are now logged in');
                setSnackbarType('success');
                setSnackbarOpen(true);
                setUserInfo(res);
            } else {
                setSnackbarMessage('Your user/password is invalid. Try again.');
                setSnackbarType('error');
                setSnackbarOpen(true);
            }

        } catch(err) {
            console.log(err);
            setSnackbarMessage('An error has occurred.');
            setSnackbarType('error');
            setSnackbarOpen(true);
        }
        setSigningIn(false);
    }

    function handleCloseSnackbar() {
        setSnackbarOpen(false);
    }

    return (
        <div className="container-wrapper">
            <div className="sidebar">
                <Sidebar />
            </div> 
            <div className="signin-wrapper">

                <ShopHeader 
                    title={`Sign in to your account`}
                    totalItems={0}
                />

                <Box 
                    component="form"
                    className="form-wrapper"
                    sx={{ p: 2, alignSelf: 'center' }}
                >
                    <TextField
                        style={{width: 350}}
                        error={errors.email}
                        required
                        id="outlined-disabled"
                        label="E-mail"
                        onChange={handleChange('email')}
                        onBlur={handleBlur('email')}
                        touched={touched.email ? 1: 0}
                        helperText={errors.email ? errors.email : ' '}
                    />
                    <TextField
                        style={{width: 350}}
                        error={errors.password}
                        required
                        id="outlined-disabled"
                        label="Password"
                        type="password"
                        onChange={handleChange('password')}
                        onBlur={handleBlur('password')}
                        touched={touched.password ? 1: 0}
                        helperText={errors.password ? errors.password : 'At least 8 characters length'}
                    />
                    <Link 
                        className="forgot-pass"
                        to={`/redefine-pass`}>
                        Forgot your password?
                    </Link>

                    <SigninButton 
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={signingIn}    
                    >
                        {signingIn ? 
                        <CircularProgress size={22} color='#fff' />
                        :
                        <span>Sign in</span>
                        }
                    </SigninButton>

                    <FacebookButton variant="contained" onClick={handleFacebookSignin}>
                        <FaFacebook size={22} color="#fff" style={{ marginRight: 10 }} />
                        Sign in with Facebook
                    </FacebookButton>

                    <AppleButton variant="contained" onClick={handleAppleSignin}>
                        <FaApple size={22} color="#fff" style={{ marginRight: 10 }} />
                        Sign in with Apple
                    </AppleButton>

                    <SignupButton variant="contained" onClick={handleOpenSignup}>
                        Create a new accont
                    </SignupButton>
                </Box>

            </div>
            <SnackBarNotification 
                handleClose={handleCloseSnackbar}
                open={snackbarOpen}
                vertical={`bottom`}
                horizontal={`right`}
                message={snackbarMessage}
                type={snackbarType}
            />
        </div>
    )
}