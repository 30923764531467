import React, { useEffect, useState } from 'react';
import { Sidebar } from '../components/Sidebar';
import { ShopHeader } from '../components/ShopHeader';
import '../styles/pages/general.css';
import '../styles/pages/signin.css';
import { TextField, Box, Button, CircularProgress } from '@material-ui/core';
import { green, deepOrange } from '@material-ui/core/colors';
import { styled } from '@material-ui/core';
import InputMask from 'react-input-mask';
import Api from '../helpers/Api';
import SnackBarNotification from '../components/SnackBarNotification';
import { useHistory } from 'react-router-dom';
import { validateEmail } from '../helpers/functions';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Countdown, { zeroPad } from 'react-countdown';

const VerifyCodeButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(deepOrange[800]),
    flexGrow: 1,
    backgroundColor: deepOrange[500],
    '&:hover': {
      backgroundColor: deepOrange[800],
    },
}));

const ConfirmButton = styled(Button)(({ theme }) => ({
    width: '100%',
    color: theme.palette.getContrastText(green[800]),
    marginTop: 20,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[800],
    },
}));

// const user = JSON.parse(localStorage.getItem('@appgenie/user')) || false;
const establishment = JSON.parse(localStorage.getItem('@appgenie/establishment')) || [];

export function RedefinePass() {

    const [cellphone, setCellphone] = useState('');
    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [showCodeField, setShowCodeField] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('');
    const [verifiedUser, setVerifiedUser] = useState(false);
    const [serverVerificationCode, setServerVerificationCode] = useState([]);
    const [loadingCode, setLoadingCode] = useState(false);
    const [submittingForm, setSubmittingForm] = useState(false);

    const history = useHistory();

    useEffect(() => {

    }, [snackbarOpen], [snackbarType], [snackbarMessage]);

    // const Completionist = () => showCodeField(false);

    const renderer = ({ minutes, seconds }) => {
        return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
    };

    async function handleGetCode() {

        if (!validateEmail(email)) {
            setSnackbarMessage('Invalid e-mail address');
            setSnackbarType('error');
            setSnackbarOpen(true);
            return;
        }

        let clean_cellphone = cellphone.replace(/[^\d]/g, '');
        if (clean_cellphone.length !== 10) {
            setSnackbarMessage('Invalid cellphone number');
            setSnackbarType('error');
            setSnackbarOpen(true);
            return;
        }

        setLoadingCode(true);

        let payload = {
            app_id: establishment.establishment_fk_app_id,
            user_email: email,
            user_phone: clean_cellphone,
        }

        let res = await Api.forgotPasswordCode(payload);

        try {

            if (res.code === 201) {
                setShowCodeField(true);
                setServerVerificationCode(res.data);
            } else {
                setSnackbarMessage('An error has occurred');
                setSnackbarType('error');
                setSnackbarOpen(true);
            }

        } catch(err) {
            setSnackbarMessage('An error has occurred');
            setSnackbarType('error');
            setSnackbarOpen(true);
        }
        setLoadingCode(false);
    }

    function handleCloseSnackbar() {
        setSnackbarOpen(false);
        if (snackbarType === 'success') {
            let app_url = localStorage.getItem('@appgenie/app_url') || false;
            history.push(`/app/${app_url}`);
        }
    }

    const NewPassSchema = Yup.object().shape({
        code: Yup.string()
            .required('Required')
            .min(4).max(4)
            .matches(/^[0-9]+$/, "Only numbers allowed")
            .typeError('4 numbers length')
            .oneOf([serverVerificationCode.verification_code, null], 'Wrong code number'),
        password: Yup.string()
            .min(8, 'Too Short!')
            .max(15, 'Too Long!')
            .required('At least 8 characters length'),
        password2: Yup.string()
            .min(8, 'Too Short!')
            .max(15, 'Too Long!')
            .required('Passwords must match')
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    const {
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        errors,
        touched
      } = useFormik({
        validationSchema: NewPassSchema,
        initialValues: { code: '', password: '', password2: '' },
        onSubmit: values => {
            redefinePassConfirm(values.password);
        }
         
    });

    async function redefinePassConfirm(new_password) {

        try {

            let payload = {
                user_id: serverVerificationCode.user_id,
                user_password: new_password,
            }

            let res = await Api.forgotPasswordConfirm(payload);
            if (res.code === 201) {

                setSnackbarMessage('Your password was changed successfully');
                setSnackbarType('success');
                setSnackbarOpen(true);
                setUserInfo(res);

            } else {

                setSnackbarMessage('An error has occurred');
                setSnackbarType('error');
                setSnackbarOpen(true);
            }

        } catch (err) {

            setSnackbarMessage('An error has occurred');
            setSnackbarType('error');
            setSnackbarOpen(true);

        }

    }

    function setUserInfo(res) {

        res.data.loginTime = new Date().getTime();
        localStorage.setItem('@appgenie/user', JSON.stringify(res.data));

        if (res.data.user_verified === 0 || res.data.user_verified === false) {
            history.push('/verify-account');
        } else {
            let app_url = localStorage.getItem('@appgenie/app_url') || false;
            history.push(`/app/${app_url}`);
        }

        /* @TODO
        var hours = 24; // Reset when storage is more than 24hours
        var now = new Date().getTime();
        var setupTime = localStorage.getItem('loginTime');
        if(now-setupTime > hours*60*60*1000) {
            localStorage.clear()
        }*/
    }

    return (
        <div className="container-wrapper">
            <div className="sidebar">
                <Sidebar />
            </div> 

            
            <div className="signin-wrapper">

                <ShopHeader 
                    title={`I forgot my password`}
                    totalItems={0}
                />

                {!verifiedUser ?
                <>
                <p className='form-tip'>
                    In order for you to redefine your password, please confirm your e-mail and cellphone number.
                </p>

                
                <Box 
                    component="form"
                    className="form-wrapper"
                    sx={{ p: 2, alignSelf: 'center' }}
                >

                    <TextField
                        style={{width: 350}}
                        required
                        id="outlined-disabled"
                        label="E-mail"
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                    />
            
                    <InputMask
                        mask="999 999 9999"
                        onChange={t=>setCellphone(t.target.value)}
                        maskChar=" "
                        value={cellphone}
                    >
                        {() => <TextField
                                    style={{width: 350}}
                                    id="outlined-disabled"
                                    label="Cellphone"
                                    required
                                />}
                    </InputMask>

                    <VerifyCodeButton 
                        variant="contained"
                        style={{width: 350, marginTop: 10, marginBottom: 20}}
                        onClick={showCodeField ? () => {} : handleGetCode}
                        disabled={verificationCode.length > 0 || loadingCode}
                    >
                        {showCodeField ?
                            <>
                            <Countdown 
                                date={Date.now() + 300000} 
                                renderer={renderer}
                                onComplete={() => setShowCodeField(false)} 
                            />
                            </>
                        :
                            (loadingCode ? 
                                <CircularProgress size={22} color='#fff' />
                            :
                                <span>Send me a code</span>
                            )
                        }
                         
                    </VerifyCodeButton>

                    {showCodeField && 
                    <>
                        <TextField
                            required
                            id="code"
                            name="code"
                            type="text"
                            label="Verification code"
                            maxLength={4}
                            inputProps={{
                                style: {
                                    fontSize: 40,
                                    width: 170,
                                    alignSelf: 'center',
                                    textAlign: 'center',
                                    color: '#666',
                                }}}
                            onChange={handleChange('code')}
                            onBlur={handleBlur('code')}
                            touched={touched.code}
                            helperText={errors.code ? errors.code : 'Exactly 4 numbers length'}
                            onInput = {(e) =>{
                                e.target.value = e.target.value.toString().slice(0,4)
                            }}
                        />

                        <TextField
                            style={{width: 350}}
                            error={errors.password}
                            required
                            id="password"
                            label="New password"
                            type="password"
                            onChange={handleChange('password')}
                            onBlur={handleBlur('password')}
                            touched={touched.password}
                            helperText={errors.password ? errors.password : 'At least 8 characters length (max 15)'}
                            inputProps={{
                                maxLength: 15,
                                style: {
                                    marginTop: 20,
                                }
                            }}
                        />

                        <TextField
                            style={{width: 350}}
                            error={errors.password2}
                            required
                            id="repeat-password"
                            label="Retype your new password"
                            type="password"
                            onChange={handleChange('password2')}
                            onBlur={handleBlur('password2')}
                            touched={touched.password}
                            helperText={errors.password ? errors.password : 'Must match with the field above'}
                            inputProps={{
                                maxLength: 15,
                            }}
                        />

                        <ConfirmButton
                            onClick={handleSubmit}
                            disabled={submittingForm}
                        >
                            {submittingForm ?
                                <CircularProgress size={22} color='#fff' />
                            :
                                <span>Confirm</span>
                            }
                        </ConfirmButton>
                    </>
                    }

                    <SnackBarNotification 
                        handleClose={handleCloseSnackbar}
                        open={snackbarOpen}
                        vertical={`bottom`}
                        horizontal={`right`}
                        message={snackbarMessage}
                        type={snackbarType}
                    />

                </Box>

                </>
                :
                <div style={{color: '#666', alignSelf: 'center', width: 350, textAlign: 'center', marginTop: 50}}>

                    <h2>Your account is now verified. Now how about having a delicious meal?</h2>
                </div>
                }
            </div>
            
        </div>
    )
}