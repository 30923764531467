import React, { useState, useEffect } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { CustomTheme } from './CustomTheme';
import '../styles/components/sidebar.css';

export function Sidebar({goHome}) {

    const [ bgcolor, setBgcolor ] = useState(localStorage.getItem('@appgenie/bgcolor') || 'transparent');
    const [ textcolor, setTextcolor ] = useState(localStorage.getItem('@appgenie/textcolor') || '#000');
    const [ establishmentName, setEstablishmentName ] = useState(localStorage.getItem('@appgenie/establishment_name') || 'App Genie');

    // useEffect(() => {
    //     setBgcolor('black');

    // }, [bgcolor]);

    const { goBack } = useHistory();
    const history = useHistory();

    function goTo() {
        let appUrl = localStorage.getItem('@appgenie/app_url') || false;
        if (appUrl) {
            history.push(`/app/${appUrl}`);
        }
    }

    return (
        <aside className="app-sidebar">
            <CustomTheme bgcolor={bgcolor} textcolor={textcolor} establishmentName={establishmentName} />
            {goHome ?
            <button type="button" onClick={() => goTo()}>
                <FiArrowLeft color={bgcolor} size={24} />
            </button>
            :
            <button type="button" onClick={goBack}>
                <FiArrowLeft color={bgcolor} size={24} />
            </button>
            }
        </aside>
    )
}