import React, { useState } from 'react';
import { Box, TextField, Button, RadioGroup, FormControlLabel, Radio, styled } from "@material-ui/core";
import { green, grey, blueGrey } from '@material-ui/core/colors';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FiCreditCard } from 'react-icons/fi';
import InputMask from 'react-input-mask';
import '../styles/components/add-credit-card.css';


const ConfirmButton = styled(Button)(({ theme }) => ({
    width: '100%',
    color: theme.palette.getContrastText(green[800]),
    marginTop: 20,
    backgroundColor: green[500],
    '&:hover': {
        backgroundColor: green[800],
    },
}));

const CancelButton = styled(Button)(({ theme }) => ({
    width: '100%',
    color: theme.palette.getContrastText(grey[200]),
    marginTop: 20,
    backgroundColor: grey[300],
    '&:hover': {
        backgroundColor: blueGrey[700],
        color: theme.palette.getContrastText(grey[900]),
    },
}));

const AddCreditCard = ({ createNewCCProfile, setNewProfile }) => {

    const [cardMask, setCardMask] = useState('9999 9999 9999 9999');
    const [cardLength, setCardLength] = useState(16);

    const addCCSchema = Yup.object().shape({
        ccNumber: Yup.number()
            .typeError(`${cardLength} numbers are required`)
            .required('Required')
            .min(cardLength, `Too short, needs to be ${cardLength} caracters`)
            .label('Credit Card Number'),
        ccExpiry: Yup.string()
            .required('Required')
            .transform(value => value.replace(/[^\d]/g, ''))
            .min(4, 'MM/YY')
            .max(4, 'MM/YY')
            .label('Expiration date'),
        ccCvv2: Yup.string()
            .required('Required')
            .matches(/^[0-9]+$/, "Only digits")
            .typeError('Min: 3 numbers')
            .min(3, 'Min: 3 numbers')
            .max(5)
            .label('CVV'),
        ccPostal: Yup.string()
            .required('Required')
            .matches(/^[0-9]+$/, "Only digits")
            .typeError('Min: 5 numbers')
            .min(5, 'Min: 5 numbers')
            .max(5)
            .label('Zipcode'),
            
    });

    const {
        values: { ccNumber, ccExpiry },
        handleChange,
        handleSubmit,
        handleBlur,
        setFieldTouched,
        errors,
        touched
      } = useFormik({
        validationSchema: addCCSchema,
        initialValues: { ccNumber: '', ccExpiry: '', ccCvv2: '', ccPostal: '' },
        onSubmit: values => {
            createNewCCProfile(values);
        }
         
    });

    const changeCcNumber = (ccNumber, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(ccNumber, true, false);
    };

    const changeccExpiry = (ccExpiry, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(ccExpiry, true, false);
    };

    function changeCardBrand(brand) {
        if (brand === 'amex') {
            setCardMask('99999 999999 9999');
            setCardLength(15);
        } else {
            setCardMask('9999 9999 9999 9999');
            setCardLength(16);
        }
    }

    return (
        <div className="newcc-wrapper">
            <h4>
                <FiCreditCard style={{marginRight: 30}} />
                Add a new credit card
            </h4>
            <Box 
                component="form"
                className="form-wrapper"
                style={{borderColor: '#e0e0e0'}}
                sx={{ p: 2 }}
            >

                <RadioGroup
                    style={{width: 350, marginBottom: 30, textAlign: 'left'}}
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="other"
                    name="radio-buttons-group"
                >
                    <FormControlLabel 
                        value="amex" 
                        control={<Radio />} 
                        label="Amex" 
                        onChange={e => changeCardBrand(e.target.value)}
                    />
                    <FormControlLabel 
                        value="other" 
                        control={<Radio />} 
                        label="Other" 
                        onChange={e => changeCardBrand(e.target.value)}
                    />
                </RadioGroup>

                <InputMask
                        mask={cardMask}
                        value={ccNumber}
                        onChange={changeCcNumber.bind(null, "ccNumber")}
                    >

                    {() =>  <TextField
                                style={{width: 350, marginBottom: 30}}
                                required
                                id="ccNumber"
                                label="Type your card number"
                                name="ccNumber"
                                touched={touched.ccNumber ? 1: 0}
                                helperText={errors.ccNumber ? errors.ccNumber : ' '}
                                error={errors.ccNumber ? true : false}
                        />}
                </InputMask>

                <div className='aditional-info'>

                <InputMask
                        mask="99/99"
                        value={ccExpiry}
                        onChange={changeccExpiry.bind(null, "ccExpiry")}
                    >

                    {() =>  <TextField
                                style={{width: 100}}
                                variant='outlined'
                                required
                                id="ccExpiry"
                                label="Valid thru"
                                name="ccExpiry"
                                touched={touched.ccExpiry ? 1: 0}
                                helperText={errors.ccExpiry ? errors.ccExpiry : ' '}
                                error={errors.ccExpiry ? true : false}
                        />}
                </InputMask>

                <TextField
                    style={{width: 125}}
                    variant='outlined'
                    required
                    id="ccCvv2"
                    label="CVV"
                    onBlur={handleBlur('ccCvv2')}
                    onChange={handleChange('ccCvv2')}
                    touched={touched.ccCvv2 ? 1: 0}
                    helperText={errors.ccCvv2 ? errors.ccCvv2 : ' '}
                    error={errors.ccCvv2 ? true : false}
                    inputProps={{ maxLength: 5 }}
                />

                <TextField
                    style={{width: 125}}
                    variant='outlined'
                    required
                    id="ccPostal"
                    label="Postal Code"
                    onBlur={handleBlur('ccPostal')}
                    onChange={handleChange('ccPostal')}
                    touched={touched.ccPostal ? 1: 0}
                    helperText={errors.ccPostal ? errors.ccPostal : ' '}
                    error={errors.ccPostal ? true : false}
                    inputProps={{ maxLength: 5 }}
                />

                </div>

                <ConfirmButton onClick={handleSubmit}>
                    Save this card
                </ConfirmButton>

                <CancelButton onClick={() => setNewProfile(false)}>
                    Cancel
                </CancelButton>

            </Box>
        </div>
    )
}

export default AddCreditCard;