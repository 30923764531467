import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Chip,
    FormControlLabel,
    Radio,
    TextField,
    Button,
    CircularProgress,
    styled
} from "@material-ui/core";
import { green, grey, blueGrey } from '@material-ui/core/colors';
// import { FiTrash } from 'react-icons/fi';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { isPointInPolygon } from 'geolib';
import '../styles/pages/cart.css';
import SearchLocationInput from "../helpers/SearchLocationInput";
import Api from '../helpers/Api';
import SnackBarNotification from './SnackBarNotification';

const ConfirmButton = styled(Button)(({ theme }) => ({
    width: '100%',
    color: theme.palette.getContrastText(green[800]),
    marginTop: 20,
    backgroundColor: green[500],
    '&:hover': {
        backgroundColor: green[800],
    },
}));

const AddAddressButton = styled(Button)(({ theme }) => ({
    width: '100%',
    color: theme.palette.getContrastText(grey[200]),
    marginTop: 20,
    backgroundColor: grey[300],
    '&:hover': {
        backgroundColor: blueGrey[700],
        color: theme.palette.getContrastText(grey[900]),
    },
}));

var user = JSON.parse(localStorage.getItem('@appgenie/user')) || false;
// var establishment = JSON.parse(localStorage.getItem('@appgenie/establishment')) || false;

export default function DeliveryAddresses({ dialogShow, handleCloseDialog, user, getDeliveryAddress, setDeliveryFee, loading }) {

    const [establishment, setEstablishment] = useState(null);
    const [pickedAddress, setPickedAddress] = useState(0);
    const [newAddress, setNewAddress] = useState(false);
    const [newAddressTitle, setNewAddressTitle] = useState(null);
    const [newAddressComplement, setNewAddressComplement] = useState(null);
    const [newAddressInfo, setNewAddressInfo] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarType, setSnackbarType] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [newAddressButtonDisabled, setNewAddressButtonDisabled] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [useThisAddressButtonDisabled, setUseThisAddressButtonDisabled] = useState(false);

    const handleCloseDialogClean = () => {
        handleCloseDialog();
    }

    useEffect(() => {
        const retrievedData = JSON.parse(localStorage.getItem('@appgenie/establishment')) || null;
        setEstablishment(retrievedData);
    }, []);

    const handlePickedAddress = (event, index) => {
        // alert(user.user_address[index].user_address_street);
        // console.log(`picked address: ${user.user_address[index].user_address_street}`);
        setPickedAddress(index);
    }

    function handleConfirmPickedAddress(index = 'latest') {
        setUseThisAddressButtonDisabled(true);
        setIsSubmitting(true);
        try {
            let myIndex = index === 'latest' ? user.user_address.length - 1 : pickedAddress;
            console.log('...............MyIndex.........................');
            console.log(myIndex);
            console.log('........................................');
            let deliveryAddress = user.user_address[myIndex];
            deliveryAddress.lat = deliveryAddress.user_address_lat;
            deliveryAddress.lng = deliveryAddress.user_address_lng;
            deliveryAddress.deliveryType = 2;
            deliveryAddress.newAddress = false;
            checkDeliveryAddress(deliveryAddress).then(
                res => {
                    if (res) {
                        localStorage.setItem('@appgenie/deliveryAddress', JSON.stringify(deliveryAddress));
                        getDeliveryAddress(() => deliveryAddress);
                        setNewAddressInfo([]);
                        if (index !== 'latest') {
                            handleCloseDialogClean();
                        }
                        console.log('ok');
                        console.log('........................................');
                        console.log(deliveryAddress);
                        console.log('........................................');

                    } else {
                        console.log('not ok');

                    }
                });
            // localStorage.setItem('@appgenie/deliveryAddress', JSON.stringify(deliveryAddress));
            // let isValidDeliveryAddress = await getDeliveryAddress(deliveryAddress);
            // if (isValidDeliveryAddress) {
            //     console.log('valid address');
            //     setNewAddressInfo([]);
            //     handleCloseDialogClean();
            // } else {
            //     console.log(`isValidDeliveryAddress: ${isValidDeliveryAddress}`);
            //     console.log('invalid address');
            // }

        } catch (err) {
            console.log(err);
            console.log('Error on handleConfirmPickedAddress');
            setSnackbarMessage('An error has occurred. Please, try again later.');
            setSnackbarType('error');
            setSnackbarOpen(true);
        }
        setIsSubmitting(false);
        setUseThisAddressButtonDisabled(false);
    }

    async function handleAddNewAddress() {

        if (newAddressInfo === undefined) {
            setSnackbarMessage('Please, confirm your address and make sure it\'s correct.');
            setSnackbarType('error');
            setSnackbarOpen(true);
            return;
        }

        if (typeof newAddressInfo.street_number === 'undefined') {
            setSnackbarMessage('Please, confirm your address and make sure it\'s correct.');
            setSnackbarType('error');
            setSnackbarOpen(true);
            return;
        }

        setIsSubmitting(true);
        setNewAddressButtonDisabled(true);
        // alert('teste');

        // var newAddress = JSON.parse(localStorage.getItem('@appgenie/newAddress')) || false;

        let payload = {};
        if (user && typeof newAddressInfo.street_number !== 'undefined') {

            let userNewAddress = `${newAddressInfo.street_number} ${newAddressInfo.route}`;
            // create let-variable to replace const and accept changes
            let userNewAddressComplement = newAddressComplement;
            let completeAddress = `${userNewAddress} ${userNewAddressComplement}`;

            if (userNewAddressComplement === null) {
                userNewAddressComplement = '';
            }

            if (completeAddress.length > 60) {
                userNewAddress = userNewAddress.substring(0, 60);
                userNewAddressComplement = userNewAddressComplement.substring(0, 60 - userNewAddress.length);
            }

            payload = {
                user_id: user.user_id,
                address: userNewAddress,
                complement: userNewAddressComplement,
                city: newAddressInfo.locality,
                zipcode: newAddressInfo.postal_code,
                state: newAddressInfo.administrative_area_level_1,
                title: newAddressTitle,
                lat: newAddressInfo.lat,
                lng: newAddressInfo.lng,
            }

            console.log('===================================================');
            console.log('handleAddNewAddress');
            console.log(payload);
            console.log('===================================================');

            const response = await Api.addUserAddress(payload);
            if (response.code === 201 || response.code === 200) {
                response.data.user_address_lat = newAddressInfo.lat;
                response.data.user_address_lng = newAddressInfo.lng;
                addUserAddress(response.data, user);
                newAddressInfo.new_address = true;
                newAddressInfo.deliveryType = 2;
                console.log('-----> updating deliveryAddress');
                localStorage.setItem('@appgenie/deliveryAddress', JSON.stringify(newAddressInfo))

                setSnackbarMessage('Your address was successfully saved.');
                setSnackbarType('success');
                setSnackbarOpen(true);
                setNewAddressInfo([]);
                setNewAddress(false);
                handleConfirmPickedAddress('latest');
            } else {
                console.log('error 1');
                console.log('response code is not what we expected');
                console.log(response.code);
                setSnackbarMessage('An error has occurred. Please, try again.');
                setSnackbarType('error');
                setSnackbarOpen(true);
            }

        } else {
            console.log('error 2');
            console.log(user);
            console.log(newAddressInfo.street_number);
            setSnackbarMessage('An error has occurred. Please, try again later.');
            setSnackbarType('error');
            setSnackbarOpen(true);
        }

        setIsSubmitting(false);
        setNewAddressButtonDisabled(false);

    }

    // Add new address to user local storage info
    function addUserAddress(savedAddress, user) {

        if (typeof user.user_address === 'undefined') {
            user.user_address = [];
        }
        user.user_address.push(savedAddress);
        localStorage.setItem('@appgenie/user', JSON.stringify(user));
    }

    async function checkDeliveryAddress(deliveryAddress) {

        // console.log('Delivery Address');
        // console.log(deliveryAddress);
        if (establishment) {
            console.log('establishment found');
            let deliveryFee = await getDeliveryFee(
                deliveryAddress.lat,
                deliveryAddress.lng,
                establishment);

            if (deliveryFee !== -1 && deliveryFee !== undefined) {

                console.log(`deliveryFee: ${deliveryFee}`);
                // deliveryAddress.delivey_fee = deliveryFee;
                localStorage.setItem('@appgenie/deliveryFee', deliveryFee);
                deliveryAddress.delivery_fee = deliveryFee;
                setNewAddressInfo(deliveryAddress);
                setNewAddressButtonDisabled(false);
                // setSnackbarMessage("Your address was successfully saved.");
                return true;

            } else {

                console.log('delivery fee problem');
                console.log(deliveryFee);
                setSnackbarMessage("Sorry, we don't delivery to this area. Please, select another address or pick-up at the store");
                setSnackbarType('error');
                setSnackbarOpen(true);
                return false;

            }

        } else {

            console.log('establishment is empty');
            console.log(establishment);
            setSnackbarMessage('An error has occurred. Please, try again...');
            setSnackbarType('error');
            setSnackbarOpen(true);

        }

    }

    const getDeliveryFee = async (latitude, longitude, establishment) => {
        if (establishment.delivery_rules == 1) {
            for (var i = 0; i < establishment.delivery_area.length; i++) {
                let item = establishment.delivery_area[i];
                console.log('Checking if isPointInPolygon TRUE');
                console.log(`latitude ${latitude}`);
                console.log(`longitude ${longitude}`);
                console.log(item.delivery_area_description);
                console.log('coordinates:');
                console.log(item.coordinates);
                let isArea = await isPointInPolygon(
                    { latitude: latitude, longitude: longitude },
                    item.coordinates,
                );

                console.log('isArea');
                console.log(isArea);

                if (isArea) {
                    console.log('isPointInPolygon TRUE');
                    console.log('delivery fee: ', item.delivery_area_fee);
                    setDeliveryFee(item.delivery_area_fee);
                    return item.delivery_area_fee;
                }
            }

            return -1;
        }
    };

    function handleCloseAlert() {
        setSnackbarOpen(false);
        if (snackbarType === 'success') {
            handleCloseDialogClean();
        }

    }


    return (
        <Dialog
            open={dialogShow}
            onClose={handleCloseDialogClean}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onBackdropClick={handleCloseDialogClean}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title" style={{ color: '#666666' }}>
                {newAddress ? <span>Add a new address:</span>
                    : <span>Please choose a delivery address and confirm:</span>}
                <IoMdCloseCircleOutline
                    color="grey"
                    onClick={handleCloseDialogClean}
                    style={{ cursor: 'pointer', float: 'right', marginTop: '5px', width: '20px' }} />
            </DialogTitle>
            <DialogContent>

                {!newAddress ?
                    <>
                        {user.user_address && user.user_address.map((item, index) => (
                            <section key={index} className="address-list">
                                <div className="address-item">
                                    <FormControlLabel
                                        value="female"
                                        checked={pickedAddress === index}
                                        control={<Radio onChange={(e) => handlePickedAddress(e, index)} />}
                                    />
                                </div>
                                <div className="address-item" style={{ flexGrow: 6 }}>
                                    {item.user_address_street} {item.user_address_complement}<br />
                                    {item.user_address_city} - {item.state_abreviations}
                                </div>
                                {/* <div className="address-item">
                                    <FiTrash size={22} color="#666" />
                                </div> */}
                                <div className="address-item">
                                    <Chip
                                        label={item.user_address_title !== null ? item.user_address_title : 'Untitled'}
                                        color="primary"
                                        variant={pickedAddress === index ? `default` : `outlined`}
                                        style={{ width: 100 }}
                                    />
                                </div>
                            </section>
                        ))}

                        {user.user_address ?
                            <ConfirmButton
                                variant="contained"
                                onClick={handleConfirmPickedAddress}
                                disabled={useThisAddressButtonDisabled}
                            >
                                <span>Use this address</span>
                            </ConfirmButton>
                            :
                            <span className="picked-address">You don't have any registered address yet</span>
                        }

                        <AddAddressButton
                            variant="contained"
                            onClick={() => setNewAddress(true)}
                            disabled={false}
                        >
                            <span>Add a new address</span>
                        </AddAddressButton>

                    </>

                    :
                    <>
                        <TextField
                            id="outlined-number"
                            label="Address Title (Home, Work, etc...)"
                            value={newAddressTitle}
                            onChange={(e) => setNewAddressTitle(e.target.value)}
                            variant='outlined'
                            style={{ marginBottom: 10, marginTop: 20 }}
                            size="small"
                            // helperText={`I.E.: Home, Work, etc`}
                            fullWidth
                        />

                        <SearchLocationInput
                            onChange={() => null}
                            setNewAddressInfo={checkDeliveryAddress}
                            setNewAddressButtonDisabled={setNewAddressButtonDisabled} />

                        <TextField
                            id="outlined-complement"
                            placeholder="Complement (if any)"
                            value={newAddressComplement}
                            onChange={(e) => setNewAddressComplement(e.target.value)}
                            variant='outlined'
                            style={{ marginTop: 10 }}
                            size="small"
                            helperText={`I.E.: Apto 102, Flat B, etc`}
                            fullWidth
                        />

                        <ConfirmButton
                            variant="contained"
                            onClick={handleAddNewAddress}
                            disabled={newAddressButtonDisabled}
                        >
                            {isSubmitting ?
                                <CircularProgress size={22} color='#fff' />
                                :
                                <span>Save this address</span>
                            }
                        </ConfirmButton>

                        <AddAddressButton
                            variant="contained"
                            onClick={() => setNewAddress(false)}
                            disabled={false}
                        >
                            <span>Cancel</span>
                        </AddAddressButton>
                    </>
                }

            </DialogContent>

            <SnackBarNotification
                handleClose={handleCloseAlert}
                open={snackbarOpen}
                vertical={`top`}
                horizontal={`center`}
                message={snackbarMessage}
                type={snackbarType}
            />

        </Dialog>
    )
}

