export const BASE_API = 'https://api.appgenie.site/app-genie';

export const FIREBASE = {
        apiKey: "AIzaSyCCNmWZazP1Ayvvmgccf-CuH0nyrZEmO30",
        authDomain: "appgenie-web.firebaseapp.com",
        projectId: "appgenie-web",
        storageBucket: "appgenie-web.appspot.com",
        messagingSenderId: "575082871839",
        appId: "1:575082871839:web:47c550029b11cd90f9e92a",
        measurementId: "G-YQ8T0QE899"
};

export const KITCHEN_BASE_URL = 'https://kitchen.appgenie.site/genie-kitchen';
