import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// importing pages
import { Home } from './pages/Home';
import { CategoryItems } from './pages/CategoryItems';
import { DetailItems } from './pages/DetailItems';
import { Cart } from './pages/Cart';
import { SignIn } from './pages/SignIn';
import { SignUp } from './pages/SignUp';
import { Profile } from './pages/Profile';
import { RedefinePass } from './pages/RedefinePass';
import { VerifyAccount } from './pages/VerifyAccount';
import { RemoveMyAccount } from './pages/RemoveMyAccount';
import { NotFound } from './pages/NotFound';
import { AllApps } from './pages/AllApps';

const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/app/:app_url/cancel-account" component={RemoveMyAccount} />
                <Route path="/app/:app_url/:table_nr" component={Home} />
                <Route path="/app/:app_url" component={Home} />
                <Route path="/menu-category-items/:category_id" component={CategoryItems} />
                <Route path="/menu-item/:item_id" component={DetailItems} />
                <Route path="/cart" component={Cart} />
                <Route path="/get-all-apps" component={AllApps} />
                <Route path="/signin" component={SignIn} />
                <Route path="/signup" component={SignUp} />
                <Route path="/profile" component={Profile} />
                <Route path="/redefine-pass" component={RedefinePass} />
                <Route path="/verify-account" component={VerifyAccount} />
                <Route path="*" component={NotFound} />
            </Switch>
        </BrowserRouter>
    )
}

export default Routes;