import * as React from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    useMediaQuery, 
    useTheme,
    styled } from '@material-ui/core';
import { green, blueGrey, grey, lightBlue } from '@material-ui/core/colors';

const Confirm = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(green[800]),
  width: 120,
  backgroundColor: green[500],
  '&:hover': {
    backgroundColor: green[800],
  },
}));

const Cancel = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blueGrey[100]),
  width: 120,
  borderWidth: 1,
  borderColor: '#888',
  borderStyle: 'solid',
  backgroundColor: 'white',
  '&:hover': {
    backgroundColor: blueGrey[100],
  },
}));

export default function ResponsiveDialog({ 
    open, 
    titleText, 
    bodyText, 
    cancelAction, 
    confirmAction, 
    handleClose,
    buttonConfirmLabel,
    buttonCancelLabel }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" style={{backgroundColor: '#f6f6f6'}}>
          {titleText}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {bodyText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Cancel autoFocus onClick={cancelAction}>
          {buttonCancelLabel ? buttonCancelLabel : `Cancel`}
          </Cancel>
          <Confirm onClick={confirmAction} autoFocus>
          {buttonConfirmLabel ? buttonConfirmLabel : `Confirm`}
          </Confirm>
        </DialogActions>
      </Dialog>
  );
}
