import React, { useEffect, useState } from 'react';
import { Sidebar } from '../components/Sidebar';
import { ShopHeader } from '../components/ShopHeader';
import '../styles/pages/general.css';
import '../styles/pages/signin.css';
import { TextField, Box, Button } from '@material-ui/core';
import { green, deepOrange } from '@material-ui/core/colors';
import { styled } from '@material-ui/core';
import InputMask from 'react-input-mask';
import Api from '../helpers/Api';
import SnackBarNotification from '../components/SnackBarNotification';
import { useHistory } from 'react-router-dom';

const VerifyCodeButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(deepOrange[800]),
    flexGrow: 1,
    backgroundColor: deepOrange[500],
    '&:hover': {
      backgroundColor: deepOrange[800],
    },
}));

const ConfirmButton = styled(Button)(({ theme }) => ({
    width: '100%',
    color: theme.palette.getContrastText(green[800]),
    marginTop: 20,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[800],
    },
}));

const AnotherAccountButton = styled(Button)(({ theme }) => ({
    width: '100%',
    color: theme.palette.getContrastText(green[800]),
    marginTop: 20,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[800],
    },
}));

const CodeField = styled(TextField)(() => ({
    fontSize: 35
}));

const user = JSON.parse(localStorage.getItem('@appgenie/user')) || false;
const establishment = JSON.parse(localStorage.getItem('@appgenie/establishment')) || [];

export function VerifyAccount() {

    const [cellphone, setCellphone] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [showCodeField, setShowCodeField] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('');
    const [verifiedUser, setVerifiedUser] = useState(false);
    const [establishment, setEstablishment] = useState(JSON.parse(localStorage.getItem('@appgenie/establishment')) || []);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('@appgenie/user')) || false);

    const history = useHistory();

    useEffect(() => {

        const user = JSON.parse(localStorage.getItem('@appgenie/user')) || false;
        if (!user || (user.user_verified === 1 || user.user_verified === true)) {
            // console.log('User verified = true')
            let app_url = localStorage.getItem('@appgenie/app_url') || false;
            history.push(`/app/${app_url}`);
        }
        console.log(establishment);

    }, [snackbarOpen], [snackbarType], [snackbarMessage], [establishment], [user]);

    async function handleGetCode() {

        let clean_cellphone = cellphone.replace(/[^\d]/g, '');
        if (clean_cellphone.length !== 10) {
            setSnackbarMessage('Telephone number is invalid.');
            setSnackbarType('error');
            setSnackbarOpen(true);
            return;
        } 

        let payload = {
            app_id: establishment.establishment_fk_app_id,
            user_id: user.user_id,
            user_phone: clean_cellphone,
        }

        console.log(payload);
        let res = await Api.sendCode(payload);
        console.log(res);

        try {

            if (res.code === 201) {
                setShowCodeField(true);
            } else {
                setSnackbarMessage('An error has occurred');
                setSnackbarType('error');
                setSnackbarOpen(true);
            }

        } catch(err) {
            setSnackbarMessage('An error has occurred');
            setSnackbarType('error');
            setSnackbarOpen(true);
        }
    }

    function handleCloseSnackbar() {
        setSnackbarOpen(false);
        if (snackbarType === 'success') {
            setVerifiedUser(true);
        }
    }

    async function handleSubmit() {

        let payload = {
            user_id: user.user_id,
            user_verification_code: verificationCode,
        }

        console.log(payload);
        let res = await Api.verifyCode(payload);
        console.log(res);

        try {

            if (res.code === 201) {
                setSnackbarMessage('Your account has been successfully verified.');
                setSnackbarType('success');
                setSnackbarOpen(true);
                user.user_verified = true;
                localStorage.removeItem('@appgenie/user');
                localStorage.setItem('@appgenie/user', JSON.stringify(user));

            } else {
                setSnackbarMessage('Invalid code. Please try again.');
                setSnackbarType('error');
                setSnackbarOpen(true);
            }

        } catch(err) {
            setSnackbarMessage('An error has occurred');
            setSnackbarType('error');
            setSnackbarOpen(true);
        }
        
    }

    // const VerifyCodeSchema = Yup.object().shape({
    //     cellphone: Yup.string()
    //         .required('Required')
    //         .transform(value => value.replace(/[^\d]/g, ''))
    //         .min(11, 'Cellphone number too short')
    //         .max(11, 'Cellphone number Too long'),
    //     code: Yup.string()
    //         .min(4, 'Too Short!')
    //         .max(4, 'Too Long!')
    //         .required('Required')
    // });

    // const {
    //     handleChange,
    //     handleSubmit,
    //     handleBlur,
    //     values,
    //     errors,
    //     touched
    //   } = useFormik({
    //     validationSchema: VerifyCodeSchema,
    //     initialValues: { cellphone: '', code: '' },
    //     onSubmit: values => {
    //         alert(`Cellphone: ${values.cellphone}, Code: ${values.code}`);
    //         let user = JSON.parse(localStorage.getItem('@appgenie/user')) || [];
    //     }
    //     //   alert(`Cellphone: ${values.cellphone}, Code: ${values.code}`)
    //         // validateCellphone(values)
          
    // });

    return (
        <div className="container-wrapper">
            <div className="sidebar">
                <Sidebar />
            </div> 

            
            <div className="signin-wrapper">

                <ShopHeader 
                    title={`Verify my account`}
                    totalItems={0}
                />

                {!verifiedUser ?
                <>
                <p className='form-tip'>
                    Almost there! Please inform your cellphone number, 
                    so we can send you a verification code and validate your account.
                </p>

                
                <Box 
                    component="form"
                    className="form-wrapper"
                    sx={{ p: 2, alignSelf: 'center' }}
                >
            
                <InputMask
                    mask="999 999 9999"
                    onChange={t=>setCellphone(t.target.value)}
                    maskChar=" "
                    value={cellphone}
                >
                    {() => <TextField
                                style={{width: 350}}
                                id="outlined-disabled"
                                label="Cellphone"
                                required
                            />}
                </InputMask>

                <VerifyCodeButton 
                    variant="contained"
                    style={{width: 350, marginTop: 10, marginBottom: 20}}
                    onClick={handleGetCode}
                    disabled={verificationCode.length > 0}
                >
                    Send me a code
                </VerifyCodeButton>


                    {showCodeField && 
                    <>
                        <InputMask
                            mask="9999"
                            onChange={t=>setVerificationCode(t.target.value)}
                            maskChar=" "
                            value={verificationCode}
                        >
                            {() => <TextField
                                    required
                                    name="code"
                                    id="outlined-disabled"
                                    label="Verification code"
                                    inputProps={{
                                        style: {
                                            fontSize: 40,
                                            width: 170,
                                            alignSelf: 'center',
                                            textAlign: 'center',
                                            color: '#666'
                                        }}}
                            />}
                        </InputMask>


                        <ConfirmButton
                            onClick={handleSubmit}
                        >
                            Confirm
                        </ConfirmButton>
                    </>
                    }

                    <SnackBarNotification 
                        handleClose={handleCloseSnackbar}
                        open={snackbarOpen}
                        vertical={`bottom`}
                        horizontal={`right`}
                        message={snackbarMessage}
                        type={snackbarType}
                    />

                </Box>

                </>
                :
                <div style={{color: '#666', alignSelf: 'center', width: 350, textAlign: 'center', marginTop: 50}}>

                    <h2>Your account is now verified. Now how about having a delicious meal?</h2>
                </div>
                }
            </div>
            
        </div>
    )
}