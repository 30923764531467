import { BASE_API, KITCHEN_BASE_URL } from './config';

// const BASE_API = 'http://127.0.0.1:5002/app-genie'

export default {

    signIn: async (payload) => {
        try {
            const req = await fetch(`${BASE_API}/user/login/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            const result = await req.json();
            return result;

        } catch (err) {
            console.log(err);
        }
    },

    socialSignIn: async (payload) => {
        try {
            const req = await fetch(`${BASE_API}/user/sign-in-social-media`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            const result = await req.json();
            return result;

        } catch (err) {
            console.log(err);
        }
    },

    signUp: async (payload) => {
        try {
            const req = await fetch(`${BASE_API}/user/registration/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            const result = await req.json();
            return result;

        } catch (err) {
            console.log(err);
        }
    },

    sendCode: async (payload) => {
        try {
            const req = await fetch(`${BASE_API}/user/send-verification-code/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            const result = await req.json();
            return result;

        } catch (err) {
            console.log(err);
        }
    },

    verifyCode: async (payload) => {
        try {
            const req = await fetch(`${BASE_API}/user/verify-code`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            const result = await req.json();
            return result;

        } catch (err) {
            console.log(err);
        }
    },
    forgotPasswordCode: async (payload) => {
        try {
            const req = await fetch(`${BASE_API}/user/forgot-password/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            const result = await req.json();
            return result;

        } catch (err) {
            console.log(err);
        }
    },
    forgotPasswordConfirm: async (payload) => {
        try {
            const req = await fetch(`${BASE_API}/user/redefine-password/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            const result = await req.json();
            return result;

        } catch (err) {
            console.log(err);
        }
    },
    addUserAddress: async (payload) => {

        /*{
            "user_id": 0,
            "address": "string",
            "complement": "string",
            "city": "string",
            "zipcode": "string",
            "state": "string",
            "title": "string",
            "lat": "string",
            "lng": "string"
        }*/

        try {
            const req = await fetch(`${BASE_API}/user/add-address`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            const result = await req.json();
            return result;

        } catch (err) {
            console.log(err);
        }
    },
    kitchenIsClosed: async (establishment_id) => {
        // data: true/false
        try {
            const req = await fetch(`${BASE_API}/kitchen/closed-kitchen/${establishment_id}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });
            const result = await req.json();
            return result;

        } catch (err) {
            console.log(err);
        }
    },
    kitchenIsItOpened: async (payload) => {
        // data: true/false
        try {
            const req = await fetch(`${BASE_API}/kitchen-is-it-opened/`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload),
            });
            const result = await req.json();
            return result;

        } catch (err) {
            console.log(err);
        }
    },
    establishmentInfo: async (app_id) => {
        try {
            const req = await fetch(`${BASE_API}/establishment-info/${app_id}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });
            const result = await req.json();
            return result;

        } catch (err) {
            console.log(err);
        }
    },
    getUserCCProfile: async (payload) => {
        try {
            const req = await fetch(`${BASE_API}/cc/get-profile`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            const result = await req.json();
            console.log(result);
            return result;

        } catch (err) {
            console.log(err);
        }
    },
    deleteUserCCProfile: async (payload) => {
        try {
            const req = await fetch(`${BASE_API}/cc/delete-profile`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            const result = await req.json();
            return result;

        } catch (err) {
            console.log(err);
        }
    },

    addUserCCProfile: async (payload) => {
        try {
            const req = await fetch(`${BASE_API}/cc/create-profile`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload),
                redirect: 'follow',
            });
            const result = await req.json();
            return result;

        } catch (err) {
            console.log(err);
        }
    },
    /*
    order = {
      cart: cart,
      totals: {
        total: checkoutInfo.totals.total,
        taxAndFees: checkoutInfo.totals.tax,
        grandTotal: grandTotal,
        tip: checkoutInfo.totals.tip,
        deliveryFee: deliveryFee,
        nonCashFee: nonCashFee
      },
      user: orderUser,
      deliveryInfo: delivery,
      establishment: establishment,
      payment_info: selectedPaymentInfo, //checkoutInfo.paymentInfo,
      reward: cartReward
    }
    response:
    {status: 'success', code: 201, data: 3339}
    */
    placeOrder: async (payload) => {
        try {
            const req = await fetch(`${BASE_API}/order-add-order`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload),
                redirect: 'follow',
            });
            const result = await req.json();
            return result;

        } catch (err) {
            console.log(err);
        }
    },

    categoryItems: async (category_id) => {
        try {

            const req = await fetch(`${KITCHEN_BASE_URL}/menu/menu-category-items/${category_id}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
            });
            const result = await req.json();
            return result;


        } catch (err) {
            console.log(err);
        }
    },

};