import React from 'react';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';


export default function SnackBarNotification({
        open, 
        handleClose, 
        vertical, 
        horizontal, 
        message,
        type}) {
    return (
        <Snackbar
            anchorOrigin={{ 
                vertical: vertical ? vertical : 'top', 
                horizontal: horizontal ? horizontal : 'right' }}
            open={open}
            autoHideDuration={3000}
            onClose={handleClose} 
        >
            <Alert onClose={handleClose} severity={type} variant='filled' sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )
}


