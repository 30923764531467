import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { FiArrowRight, FiMapPin } from 'react-icons/fi'
import { ShopHeader } from '../components/ShopHeader';
import { Loading } from '../components/Loading';
import ConfirmationDialog from '../components/ConfirmationDialog';

import '../styles/pages/home.css';
import api from '../services/api';

// https://kitchen.appgenie.site/genie-kitchen/app/get-app-info/noches-de-cuba
// https://kitchen.appgenie.site/genie-kitchen/menu/menu-categories/2
// https://kitchen.appgenie.site/genie-kitchen/menu/menu-category-items/36

export function Home() {
    const [establishmentInfo, setEstablishmentInfo] = useState([]);
    const [establishmentMenu, setEstablishmentMenu] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [removeDialogStatus, setRemoveDialogStatus] = useState(true);
    const { app_url } = useParams();
    const { table_nr } = useParams();
    const history = useHistory();

    useEffect(() => {
        console.log(app_url);
        console.log(table_nr);
        async function getInfoEstablishment() {
            try {

                let currentUrl = localStorage.getItem('@appgenie/app_url') || false;
                if (currentUrl && currentUrl !== app_url) {
                    // Clean up old sessions
                    localStorage.removeItem('@appgenie/user');
                    localStorage.removeItem('@appgenie/deliveryAddress');
                    localStorage.removeItem('@appgenie/revenueCenter');
                    localStorage.removeItem('@appgenie/deliveryFee');
                } 
                const response = await api.get(`app/get-app-info/${app_url}`);
                localStorage.setItem('@appgenie/establishment', JSON.stringify(response.data.data[0]));
                localStorage.setItem('@appgenie/app_url', app_url);
                localStorage.setItem('@appgenie/bgcolor', response.data.data[0]['app_background_color']);
                localStorage.setItem('@appgenie/textcolor', response.data.data[0]['app_text_color']);
                localStorage.setItem('@appgenie/establishment_name', response.data.data[0]['establishment_name']);
                setEstablishmentInfo(response.data.data);
                getMenuEstablishment(response.data.data[0].establishment_id);
                return;
                
            } catch (err) {
                console.log(err);
                // return history.push('/not-found');
            }
        }
        getInfoEstablishment();
        
    }, [app_url]);

    async function getMenuEstablishment(establishment_id) {
        try {
            const response = await api.get(`/menu/menu-categories/${establishment_id}`);
            setEstablishmentMenu(response.data.data);
        } catch (error) {
            return history.push('/not-found');
        }
    }

    if (!establishmentInfo[0]) {
        return <Loading />
    }

    const {
        establishment_city,
        establishment_logo,
        establishment_name,
        establishment_zipcode,
        establishment_address
    } = establishmentInfo[0];
 
    function handleRemoveDialogStatus() {
        localStorage.removeItem('@appgenie/table');
        setRemoveDialogStatus(false);
    }

    function handleConfirmTableNumber() {
        localStorage.removeItem('@appgenie/table');
        localStorage.setItem('@appgenie/table', table_nr);
        setRemoveDialogStatus(false);
    }

    return (
        <div className="app-home">
            <aside>
                <header>
                    <img
                        src={establishment_logo} alt={establishment_name}
                        loading="lazy"
                    />
                    <p>Several options for you, don't waste time, choose your dish.</p>
                </header>
                <footer>
                    <FiMapPin size={26} color="#fff"/>
                    <strong>{establishment_address}</strong>
                    <p>{establishment_city} / {establishment_zipcode}</p>
                    <a
                        style={{color: '#cfcfcf', fontSize: 11, marginTop: 20}}
                        onClick={() => {}}
                        target='_blank'
                        href={`https://privacy.appgenie.site`}
                    >
                        Terms of Service and Privacy Policy
                    </a>
                </footer>
            </aside>

            {!establishmentMenu[0] ? (
                <Loading />
            ) : (
                <div className="content">

                <ShopHeader 
                    title={`Select menu category`}
                    totalItems={totalItems}
                    backgroundColor='transparent'
                />

                    {establishmentMenu.map(category => {
                        return (
                            <Link
                                key={category.category_id}
                                to={`/menu-category-items/${category.category_id}`}
                                className="buttonMenuCategories"
                            >
                                <p>{category.category_name}</p>
                                <FiArrowRight size={24} color="#000" />
                            </Link>
                        )
                    })}
                    {table_nr &&
                    <ConfirmationDialog
                        open={removeDialogStatus}
                        titleText={`Is it a table service?`}
                        bodyText={``}
                        cancelAction={handleRemoveDialogStatus}
                        confirmAction={handleConfirmTableNumber}
                        handleClose={handleRemoveDialogStatus}
                        buttonCancelLabel={`No`}
                        buttonConfirmLabel={`Yes`}
                    />
                    }
                </div>

            )}
        </div>
    )
}