import * as React from 'react';
import {
    Button,
    Box,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    useTheme,
    styled
} from '@material-ui/core';
import { green, blueGrey } from '@material-ui/core/colors';

const Confirm = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(green[800]),
    width: 120,
    backgroundColor: green[500],
    '&:hover': {
        backgroundColor: green[800],
    },
}));

const Cancel = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(blueGrey[100]),
    width: 120,
    borderWidth: 1,
    borderColor: '#888',
    borderStyle: 'solid',
    backgroundColor: 'white',
    '&:hover': {
        backgroundColor: blueGrey[100],
    },
}));

export default function CustomTipDialog({
    open,
    titleText,
    cancelAction,
    confirmAction,
    handleClose }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [tip, setTip] = React.useState('0.00');

    function handleFormatCurrency(inputValue) {

        try {
            if (inputValue.length > 0) {
                var value = inputValue.match(/\d+/g).join('')
                if (value.length < 4) {
                    value = value.padStart(3, 0);
                } else {
                    value = String(parseInt(value));
                }
                let cents = value.substring(value.length - 2, value.length)
                let integer = value.substring(0, value.length - 2)
                setTip(`${integer}.${cents}`);
            } else {
                setTip(inputValue);
            }

        } catch (err) {
            console.log(err);
            return;
        }
    }

    const handleConfirmCustomTip = () => {
        let confirmedTip = parseFloat(tip);
        console.log(confirmedTip);
        confirmAction(confirmedTip);
        handleClose();
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title" style={{ backgroundColor: '#f6f6f6' }}>
                {titleText}
            </DialogTitle>
            <DialogContent>
                <Box
                    component="form"
                    className="form-wrapper"
                    style={{ borderColor: '#e0e0e0', width: 200 }}
                    sx={{ p: 2 }}
                >
                    <TextField
                        style={{ width: 100 }}
                        id="outlined-disabled"
                        label="Tip $"
                        value={tip}
                        onChange={t => handleFormatCurrency(t.target.value)}
                        required
                        inputProps={{ maxLength: 6 }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Cancel autoFocus onClick={cancelAction}>
                    Cancel
                </Cancel>
                <Confirm onClick={() => handleConfirmCustomTip()} autoFocus>
                    Confirm
                </Confirm>
            </DialogActions>
        </Dialog>
    );
}
