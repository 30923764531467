import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CustomTipDialog from './CustomTipDialog';


export default function OptionsButtons({options, defaultValue, setDefaultTip, setDefaultTipCalc, totalAmount}) {
    const [value, setValue] = React.useState(defaultValue);
    const [tipDialogStatus, setTipDialogStatus] = React.useState(false);

    const handleChange = (event, newValue) => {

        try {
            setValue(newValue);
            if (newValue !== 'custom') {
                let calcTip = (totalAmount * (newValue/100)).toFixed(2);
                calcTip = parseFloat(calcTip);
                // console.log(`typeof calcTip (${calcTip}): ${typeof calcTip}`);
                setDefaultTip(newValue);
                setDefaultTipCalc(calcTip)
            } else {
                setTipDialogStatus(true);
            }
        } catch(err) {
            setDefaultTip(0);
            setDefaultTipCalc(0);
        }
        
    };

    function handleRemoveDialogStatus() {
        setTipDialogStatus(false);
    }

    function handleCloseTipDialog() {
        
    }

    function handleConfirmCustomTip(customTip) {
        setDefaultTip(customTip);
        setDefaultTipCalc(customTip);
    }

    return (
        <ToggleButtonGroup
            color="primary"
            value={value}
            exclusive
            onChange={handleChange}
            fullWidth
            maxWidth="sm"
            size='small'
        >
            <ToggleButton 
                value={``}
                disabled
                style={{color: '#666'}}
            >
                Select a tip
            </ToggleButton>
            {options.map((option, index) => (
                <ToggleButton 
                    key={index} 
                    value={option.percentage !== 'None' ? option.percentage : 0}
                >
                        {option.percentage} {option.percentage !== 'None' && <span>%</span>}
                </ToggleButton>
            ))}
            <ToggleButton 
                value={`custom`}
                style={{color: '#666'}}
            >
                Custom
            </ToggleButton>
            <CustomTipDialog
                open={tipDialogStatus}
                titleText={`Custom your tip`}
                cancelAction={handleRemoveDialogStatus}
                confirmAction={handleConfirmCustomTip}
                handleClose={handleRemoveDialogStatus}
            />
        </ToggleButtonGroup>
    );
}