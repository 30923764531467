import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiShoppingCart, FiUser, FiLogOut } from 'react-icons/fi'
import { Badge } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { CustomTheme } from './CustomTheme';


export function ShopHeader({title, totalItems, backgroundColor, categoryLink}) {

    const [ bgcolor, setBgcolor ] = useState(localStorage.getItem('@appgenie/bgcolor') || 'transparent');
    const [ textcolor, setTextcolor ] = useState(localStorage.getItem('@appgenie/textcolor') || '#000');
    const [ establishmentName, setEstablishmentName ] = useState(localStorage.getItem('@appgenie/establishment_name') || 'App Genie');
    const [ appUrl, setAppUrl ] = useState(localStorage.getItem('@appgenie/app_url') || false);
    const [totalItem, setTotal] = useState(0);
    const [isLogged, setIsLogged] = useState(false);
    const [homeUrl, setHomeUrl] = useState('/get-all-apps');
    const history = useHistory();

    useEffect(() => {
    
        function shoppingCartTotalItems() {
            let a = JSON.parse(localStorage.getItem('@appgenie/items')) || [];
            var total = 0;
            if (a.length > 0) {
                a.forEach(function(item){
                    total = total + item.quantity;
                });
            }
            setTotal(total);
        }
        shoppingCartTotalItems();

        function userIsLogged() {
            let user = JSON.parse(localStorage.getItem('@appgenie/user')) || false;
            if (user) {
                setIsLogged(true);
            } else {
                setIsLogged(false);
            }
        }
        userIsLogged();

        function getHomeUrl() {
            let app_url = localStorage.getItem('@appgenie/app_url') || false;
            if (app_url) {
                setHomeUrl(`/app/${app_url}`);
            } 
        }
        getHomeUrl();

    }, [totalItem], [isLogged], [homeUrl]);

    function handleLogout() {
        localStorage.removeItem('@appgenie/user');
        localStorage.removeItem('@appgenie/user');
        localStorage.removeItem('@appgenie/deliveryAddress');
        let app_url = localStorage.getItem('@appgenie/app_url') || false;
        setIsLogged(false);
        if (app_url) {
            history.push(`/app/${app_url}`);
        } else {
            history.push('/get-all-apps');
        }
    }

    function handleSignIn() {
        localStorage.setItem('@appgenie/redir', window.location.pathname);
        history.push('/signin');
    }

    return (
        <div className="header" style={{ backgroundColor: backgroundColor ? backgroundColor : '#fff', marginRight: 0 }}>
            <CustomTheme 
                bgcolor={bgcolor} 
                textcolor={textcolor} 
                establishmentName={establishmentName}
                appUrl={appUrl}
            />
            <p>
                {categoryLink &&
                    <>
                        <Link
                            to={homeUrl}
                            style={{color: '#000'}}
                        >
                            Category
                        </Link>
                        &nbsp;&gt;
                    </>
                }
                {title}
            </p>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Link className="cart" to={`/cart`} style={{marginRight: 10}}>
                    <Badge badgeContent={totalItems} color="primary">
                        <FiShoppingCart size={22} color="#fff" />
                    </Badge>
                </Link>
                <Link className="cart" style={{marginRight: 10}} onClick={() => handleSignIn()}>
                    <Badge color="primary">
                        <FiUser size={22} color="#fff" />
                    </Badge>
                </Link>
                {isLogged && 
                    <Link className="cart" onClick={() => handleLogout()}>
                        <Badge color="primary">
                            <FiLogOut size={22} color="#fff" />
                        </Badge>
                    </Link>
                }
            </div>
        </div>
    )
}