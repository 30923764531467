import React from 'react';
import {BiErrorCircle} from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import '../styles/pages/not-found.css'

export function NotFound() {
    const history = useHistory();

    function handleNavigate() {
        return history.replace('/get-all-apps');
    }

    return (
        <div className="container-not-found">
            <BiErrorCircle size={128} color="#6680FF"/>
            <p><span>404.</span>That's an error</p>
            <button onClick={handleNavigate}>
                Back to home
            </button>
        </div>
    )
}