import React, { useEffect, useState } from 'react';
import { FiArrowDown, FiShoppingCart } from 'react-icons/fi';
import { GrFormClose } from 'react-icons/gr';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Loading } from '../components/Loading';
import { Sidebar } from '../components/Sidebar';
import api from '../services/api';
import '../styles/pages/detail-items.css';

// https://kitchen.appgenie.site/genie-kitchen/menu/menu-item/{item_id}1725

export function DetailItems() {
    const history = useHistory();
    const { item_id } = useParams();

    const [detailItem, setDetailItem] = useState([]);
    const [showModalInfoSubmit, setShowModalInfoSubmit] = useState(false);
    const [qty, setQty] = useState(0);
    const [modifiers, setModifiers] = useState([]);
    const [errorInCheckBox, setErrorInCheckBox] = useState(false);

    useEffect(() => {
        async function getInfoDetailItem() {
            try {
                api.get(`/menu/menu-item/${item_id}`)
                    .then(response => setDetailItem(response.data.data));
                return;
            } catch (error) {
                console.log(error);
                return
            }
        }
        getInfoDetailItem();
    }, [item_id]);

    if (detailItem.length === 0) {
        return <Loading />
    }

    function handleQtyIncrease() {
        setQty(qty => qty + 1)
    }
    function handleQtyDecrease() {
        var more = qty;

        if (qty === 0) {
            more = 0;
            setQty(more)
        } else {
            setQty(qty => qty - 1)
        }
    }

    function handleShowCheckboxes(classe) {
        var showCheck = document.getElementById(`${classe}`);

        showCheck.style.display === 'none' ? showCheck.style.display = 'block' : showCheck.style.display = 'none'
    }
    // function to check selected is min and max
    function handleCheckIsMinAndMaxSelected(menu_id) {
        var allCheckboxs = document.querySelectorAll(`.${CSS.escape(menu_id)}`);
        var erro = document.querySelector(`#${CSS.escape(menu_id)}`);
        var totSelected = 0;

        allCheckboxs.forEach(item => {
            totSelected += item.checked

            if (totSelected < parseInt(item.min)) {
                erro.innerHTML = `The min is ${item.min}`;
                setErrorInCheckBox(errorInCheckBox => true)
                return;
            } else if (totSelected > parseInt(item.max)) {
                erro.innerHTML = `The max is ${item.max}`;
                setErrorInCheckBox(errorInCheckBox => true)
                return;
            } else {
                erro.innerHTML = '';
                setErrorInCheckBox(errorInCheckBox => false)
            }
        });
    }
    // function to get values checkbox
    function selectedCheckBox(menu_id, modifier_id) {
        let itemIsSeleted = document.querySelector(`#${CSS.escape(modifier_id)}`).checked;
        var alreadyModifierExists = modifiers.some(e => e.modifier_id === modifier_id);
        var modiferItemSelected;

        if (itemIsSeleted && alreadyModifierExists === false) {
            var modifierMenu = detailItem.modifiers.filter(item => {
                if (menu_id === item.menu_modifier_id) {
                    return item;
                }
            });
    
            modifierMenu[0].items_modifier.filter(element => {
                if (modifier_id === element.modifier_id) {
                    return modiferItemSelected = {
                        menu_modifier_id: element.menu_modifier_id,
                        modifier_id: element.modifier_id,
                        modifier_name: element.modifier_name,
                        modifier_price: element.modifier_price,
                    }
                }
            });
            setModifiers([...modifiers, modiferItemSelected]);
        }

        if (itemIsSeleted === false && alreadyModifierExists) {
            const filteredModifiers = modifiers.filter(item => {
                if(item.modifier_id !== modifier_id) {
                    return item
                }
            });
            setModifiers(filteredModifiers);
        }
    }

    function handleShowModalConfirmation() {
        if (qty === 0) {
            return alert('Quantity cannot be zero')
        }
        if(errorInCheckBox) {
            return alert('Please verify options');
        }
        setShowModalInfoSubmit(showModalInfoSubmit => !showModalInfoSubmit);
    }

    // var db = window.openDatabase("KitchenApp", "1.0", "Cart", 50);
    var db = [];

    // prepare to insert data in table
    function handleCartInsert() {
        db.transaction(cartInsertDB, errorDB);
    }

    // inserting data in table
    function cartInsertDB(tx) {
        tx.executeSql(
            'INSERT INTO Items (nameItem, qtyItem, itemPrice, modifiers, itemImage) VALUES (?, ?, ?, ?, ?)', [
            detailItem.item_name,
            qty,
            detailItem.item_price,
            JSON.stringify(modifiers),
            detailItem.item_image
        ], function (tx, result) {
            alert('Added into cart!');
            history.push('/cart')
            return;
        }, function (tx, error) {
            alert('Error added into cart!');
            return;
        }
        );
    }
    // error database
    function errorDB(err) {
        console.log(err)
    }

    return (
        <div className="container-wrapper-item">
            <div className="sidebar">
                <Sidebar />
            </div>

            <div className="content-wrapper-item">

                <div className="header">
                    <Link className="cart" to={() => {}}>
                        <FiShoppingCart size={22} color="#fff" className="icon" />
                    </Link>
                </div>

                <div className="content-item-info">
                    <div className="item-info-image">
                        <img
                            src={detailItem.item_image}
                            alt={detailItem.item_name}
                            loading="lazy"
                        />
                    </div>

                    <div className="info-item">
                        <p>{detailItem.item_name}</p>
                        <strong>$ {detailItem.item_price.toFixed(2)}</strong>

                        <div className="quantity-select">
                            <p>Select Qty:</p>
                            <div className="buttons">
                                <button onClick={handleQtyDecrease}>
                                    &lt;
                                </button>
                                <p>{qty}</p>
                                <button onClick={handleQtyIncrease}>
                                    &gt;
                                </button>
                            </div>
                        </div>
                        {
                            (!detailItem.modifiers)
                                ? ''
                                : (
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        {detailItem.modifiers.map(modifier_item => (
                                            <div className="select-block" key={modifier_item.menu_modifier_id}>

                                                <div className="flex-info">
                                                    <p>
                                                        {modifier_item.menu_modifier_name}
                                                    </p>
                                                    <div className="button-and-modifiers-select">
                                                        <p>
                                                            {modifier_item.menu_modifier_min} - {modifier_item.menu_modifier_max}
                                                        </p>
                                                        <button onClick={() => handleShowCheckboxes(modifier_item.menu_modifier_name)}>
                                                            <FiArrowDown size={22} color="#fff" />
                                                        </button>
                                                    </div>
                                                </div>

                                                <hr />

                                                <div id={`${modifier_item.menu_modifier_name}`}
                                                    style={{ display: 'none' }}
                                                >
                                                    {modifier_item.items_modifier.map(modifier => {
                                                        return (
                                                            <div key={modifier.modifier_id} className="input-block">
                                                                <div>
                                                                    <input
                                                                        type="checkbox"
                                                                        className={modifier_item.menu_modifier_id}
                                                                        id={modifier.modifier_id}
                                                                        min={modifier_item.menu_modifier_min}
                                                                        max={modifier_item.menu_modifier_max}
                                                                        onClick={() => handleCheckIsMinAndMaxSelected(modifier_item.menu_modifier_id)}
                                                                        onChange={() =>
                                                                            selectedCheckBox(modifier_item.menu_modifier_id, modifier.modifier_id)
                                                                        }
                                                                    />
                                                                    <label>{modifier.modifier_name}
                                                                    </label>
                                                                </div>
                                                                {modifier.modifier_price === 0 ? (
                                                                    null
                                                                ) : (
                                                                    <p> ${modifier.modifier_price.toFixed(2)}</p>
                                                                )}
                                                            </div>
                                                        )
                                                    })}
                                                    <p id={modifier_item.menu_modifier_id}></p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )
                        }

                        <button
                            onClick={handleShowModalConfirmation}
                            className="container-button-add-cart"
                        >
                            Add to cart
                        </button>

                    </div>
                </div>
            </div>

            {showModalInfoSubmit && (
                <div className="modal">
                    <div className="modal-header">
                        <p>Please confirm your order</p>
                        <button onClick={handleShowModalConfirmation}>
                            <GrFormClose color="#fff" size={32} />
                        </button>
                    </div>
                    <div className="content-order">
                        <img
                            src={detailItem.item_image}
                            alt={detailItem.item_name}
                            loading="lazy"
                        />
                        <div className="info-order">
                            <p>{detailItem.item_name}</p>
                            <p>Qty: {qty}</p>
                            <strong>$ 13.00</strong>
                        </div>
                    </div>
                    <hr />
                    {modifiers.length !== 0 && (
                        <div className="modifiers-selected">
                            <p>Options</p>
                            <ul>
                                {modifiers.map((item, index) => (
                                    <li key={index}>{item.modifier_name}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <button className="confirm" onClick={handleCartInsert}>
                        <span>Ok! Confirm...</span>
                    </button>
                </div>
            )}
        </div>
    )
}