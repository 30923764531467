import React, { useEffect, useState } from 'react';
import { Sidebar } from '../components/Sidebar';
import { ShopHeader } from '../components/ShopHeader';
import InputMask from 'react-input-mask';
import { TextField, Box, Button, CircularProgress } from '@material-ui/core';
import { green, deepOrange } from '@material-ui/core/colors';
import { styled } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import '../styles/pages/general.css';
import '../styles/pages/signin.css';


const ConfirmButton = styled(Button)(({ theme }) => ({
    width: '100%',
    color: theme.palette.getContrastText(green[800]),
    marginTop: 20,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[800],
    },
}));


export function RemoveMyAccount() {

    const [cellphone, setCellphone] = useState('');
    const [email, setEmail] = useState('');
    const [submittingForm, setSubmittingForm] = useState(false);

    const CancelSchema = Yup.object().shape({
        email: Yup.string()
            .required('Required')
            .min(4).max(50),
        cellphone: Yup.string()
            .min(9, 'Too Short!')
            .max(15, 'Too Long!')
            .required('At least 8 characters length')
    });

    async function cancelAccountConfirm(new_password) {
        console.log('clicked');
    }

    const {
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        errors,
        touched
      } = useFormik({
        validationSchema: CancelSchema,
        initialValues: { email: '', cellphone: '', },
        onSubmit: values => {
            cancelAccountConfirm(values.email);
        }
         
    });
    
    return (
        <div className="container-wrapper">
            <div className="sidebar">
                <Sidebar />
            </div> 

            
            <div className="signin-wrapper">

                <ShopHeader 
                    title={`Delete account`}
                    totalItems={0}
                />

                <p className='form-tip'>
                    If you wish to delete your account, please enter your email address and cellphone number below:
                </p>

                <Box 
                    component="form"
                    className="form-wrapper"
                    sx={{ p: 2, alignSelf: 'center' }}
                >

                    <TextField
                        style={{width: 350}}
                        required
                        id="outlined-disabled"
                        label="E-mail"
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                    />
            
                    <InputMask
                        mask="999 999 9999"
                        onChange={t=>setCellphone(t.target.value)}
                        maskChar=" "
                        value={cellphone}
                    >
                        {() => <TextField
                                    style={{width: 350}}
                                    id="outlined-disabled"
                                    label="Cellphone"
                                    required
                                />}
                    </InputMask>

                    <ConfirmButton
                        onClick={handleSubmit}
                        disabled={submittingForm}
                    >
                        {submittingForm ?
                            <CircularProgress size={22} color='#fff' />
                        :
                            <span>Delete account</span>
                        }
                    </ConfirmButton>
                
                </Box>
                
                    
            </div>
            
        </div>
    )
}