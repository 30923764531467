import React, { useEffect, useState } from 'react';
import { Sidebar } from '../components/Sidebar';
import { Loading } from '../components/Loading';
import { Button, ButtonGroup, styled, CircularProgress } from '@material-ui/core';
import { blue, blueGrey, grey } from '@material-ui/core/colors';
import { FiAlertCircle, FiCheckCircle, FiShoppingCart, FiCreditCard } from 'react-icons/fi';
import { FaClosedCaptioning, FaCoins } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import ConfirmationDialog from '../components/ConfirmationDialog';
import '../styles/pages/cart.css';
import DeliveryAddresses from '../components/DeliveryAddresses';
import UserPaymentMethods from '../components/UserPaymentMethods';
import Scheduler from '../components/Scheduler';
import SnackBarNotification from '../components/SnackBarNotification';
import OptionsButtons from '../components/OptionsButtons';
import { kitchenIsClosed, kitchenIsItOpened, shoppingCartTotalItems } from '../helpers/functions';
import Api from '../helpers/Api';
import UserCartItems from '../components/UserCartItems';
import { ReactComponent as Mastercard } from '../assets/images/mc_logo.svg';
import { ReactComponent as Visa } from '../assets/images/visa_logo.svg';


const CheckoutButton = styled(Button)(({ theme }) => ({
    width: '100%',
    color: theme.palette.getContrastText(blueGrey[800]),
    marginTop: 20,
    backgroundColor: blueGrey[500],
    '&:hover': {
        backgroundColor: blueGrey[800],
    },
}));

const StepButton = styled(Button)(({ theme }) => ({
    width: '100%',
    position: 'relative',
    height: 100,
    color: theme.palette.getContrastText(blueGrey[800]),
    marginTop: 20,
    backgroundColor: blueGrey[500],
    '&:hover': {
        backgroundColor: blueGrey[300],
    },
}));

const StepHeaderButtons = ({ step, handleStepShift }) => {

    return (
        <ButtonGroup variant="text" aria-label="outlined primary button group" style={{ marginBottom: 30 }}>
            <StepButton
                style={step !== 1 ? { backgroundColor: blueGrey[100] } : {}}
                onClick={() => handleStepShift(1)}
            >
                {step === 1 &&
                    <div className="stepNumber">
                        1
                    </div>
                }
                <FiShoppingCart size={32} />
                <span style={{ marginLeft: 10 }}>Shopping cart</span>
            </StepButton>

            <StepButton
                style={step !== 2 ? { backgroundColor: blueGrey[100] } : {}}
                onClick={() => handleStepShift(2)}
            >
                {step === 2 &&
                    <div className="stepNumber">
                        2
                    </div>
                }
                <FiCreditCard size={32} />
                <span style={{ marginLeft: 10 }}>Checkout</span>
            </StepButton>

        </ButtonGroup>
    );
}

// const establishment = JSON.parse(localStorage.getItem('@appgenie/establishment')) || false;
// const user = JSON.parse(localStorage.getItem('@appgenie/user')) || false;
const cart = JSON.parse(localStorage.getItem('@appgenie/items')) || [];
const prevRevenueCenter = localStorage.getItem('@appgenie/revenueCenter') || 1;

export function Cart() {
    const [user, setUser] = useState(false);
    const [data, setData] = useState([]);
    const [totals, setTotals] = useState({
        items: 0,
        modifiers: 0,
        general: 0
    });
    const [removeDialogStatus, setRemoveDialogStatus] = useState(false);
    const [removeDialogTitle, setRemoveDialogTitle] = useState('');
    const [removeItem, setRemoveItem] = useState(0);
    const [appUrl, setAppUrl] = useState('');
    // const [revenueCenter, setRevenueCenter] = useState(prevRevenueCenter !== 'undefined' ? prevRevenueCenter : 1);
    const [revenueCenter, setRevenueCenter] = useState(1);
    // const [enableCheckout, setEnableCheckout] = useState(false);
    const [addressesDialogShow, setAddressesDialogShow] = useState(false);
    // const [deliveryAddress, setDeliveryAddress] = useState(JSON.parse(localStorage.getItem('@appgenie/deliveryAddress')) || {});
    const [deliveryAddress, setDeliveryAddress] = useState({});
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [asap, setAsap] = useState(true)
    const [scheduledDelivery, setScheduleDelivery] = useState(false);
    const [minDate, setMinDate] = useState(new Date(new Date().setHours(new Date().getHours() + 2)));
    const [maxDate, setMaxDate] = useState(new Date(new Date().setHours(new Date().getHours() + 120)));
    const [startHour, setStartHour] = useState({ hour: 0, minute: 0 });
    const [endHour, setEndHour] = useState({ hour: 0, minute: 0 });
    const [enablePayment, setEnablePayment] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('');
    const [step, setStep] = useState(1);
    const [suggestedTips, setSuggestedTips] = useState([]);
    const [defaultTip, setDefaultTip] = useState(null);
    const [defaultTipCalc, setDefaultTipCalc] = useState(null);
    const [userCards, setUserCards] = useState([]);
    const [defaultUserCard, setDefaultUserCard] = useState(false);
    const [userCardsDialogShow, setUserCardsDialogShow] = useState(false);
    const [pickedPaymentMethod, setPickedPaymentMethod] = useState(0);
    const [placingOrder, setPlacingOrder] = useState(false);
    const [orderApproved, setOrderApproved] = useState(0);
    const [nonCashFeePerc, setNonCashFeePerc] = useState(0);
    const [nonCashFeeAmount, setNonCashFeeAmount] = useState(0);
    const [establishment, setEstablishment] = useState(JSON.parse(localStorage.getItem('@appgenie/establishment')) || false);
    const [table, setTable] = useState(localStorage.getItem('@appgenie/table') || false);
    const [refreshUserCards, setRefreshUserCards] = useState(0);

    const history = useHistory();

    console.log('***************************************');
    console.log(deliveryAddress);
    console.log('***************************************');

    function cartViewItems() {
        var dataItem = [];
        var Obj;
        var totalAmount = 0;
        var modTotal = 0;

        let a = JSON.parse(localStorage.getItem('@appgenie/items')) || false;
        a !== false && typeof a.items !== 'undefined' && a.items.forEach(function (item) {

            var subtot = item.item_price * item.quantity;
            totalAmount += subtot;

            Obj = {
                item_id: item.item_id,
                item_name: item.item_name,
                item_quantity: item.quantity,
                item_price: item.item_price,
                modifiers: getPickedModifiers(item),
                item_image: item.item_image,
                sub_tot: subtot
            }
            dataItem.push(Obj);

            typeof item.modifiers !== 'undefined' && item.modifiers.forEach(function (mod) {
                if (mod.items_modifier) {
                    mod.items_modifier.forEach(function (sub) {
                        if (sub.modifier_checked) {
                            modTotal += sub.modifier_price * item.quantity;
                            if (sub.sub_modifiers) {
                                sub.sub_modifiers.items_modifier.forEach(function (resub) {
                                    if (resub.modifier_checked) {
                                        modTotal += resub.modifier_price * item.quantity;
                                    }
                                });
                            }
                        }
                    });
                }
            });

        });

        setData(dataItem);
        let generalTotal = totalAmount + modTotal;
        // console.log(`modTotal: ${modTotal}`);
        setTotals({ items: totalAmount, modifiers: modTotal, general: generalTotal });

        let totalWithTax = (generalTotal * establishment.tax) + generalTotal;

        let default_tip = establishment.tip_suggestion.filter((item) => item.tip_suggestion_default == true);
        let tipCalc = 0
        if (default_tip.length > 0) {
            setDefaultTip(default_tip[0].tip_suggestion_percent);
            tipCalc = (totalWithTax * (default_tip[0].tip_suggestion_percent))/100;
            setDefaultTipCalc(tipCalc);
            // setDefaultTipCalc(generalTotal * (default_tip[0].tip_suggestion_percent / 100));
        }

        let nonCashFeeVar = establishment.establishment_non_cash_fee;
        setNonCashFeeAmount(totalWithTax * nonCashFeeVar/100);
    }

    const pickRevenueCenter = () => {
        var pickedRevenueCenter = parseInt(localStorage.getItem('@appgenie/revenueCenter')) || false;
        if (pickedRevenueCenter) {
            if (pickedRevenueCenter === 2) {
                let deliveryInfo = JSON.parse(localStorage.getItem('@appgenie/deliveryAddress')) || false;
                if (typeof deliveryInfo.user_address_id !== 'undefined') {
                    setRevenueCenter(parseInt(pickedRevenueCenter));
                    setDeliveryAddress(deliveryInfo);
                } 
                
            } else {
                setRevenueCenter(parseInt(1));
            }
        }
    }

    useEffect(() => {
        shoppingCartTotalItems();
        let userInfo = JSON.parse(localStorage.getItem('@appgenie/user')) || false;
        setUser(userInfo);
        cartViewItems();
        setAppUrl(localStorage.getItem('@appgenie/app_url') || false);
        pickRevenueCenter();
        setNonCashFeePerc(establishment.establishment_non_cash_fee);
        // setNonCashFeePerc(establishment.establishment_non_cash_fee);

        function getDeliveryTimeRange() {

            let d = new Date();
            let weekday = new Array(7);
            weekday[0] = "Sunday";
            weekday[1] = "Monday";
            weekday[2] = "Tuesday";
            weekday[3] = "Wednesday";
            weekday[4] = "Thursday";
            weekday[5] = "Friday";
            weekday[6] = "Saturday";
            const today = weekday[d.getDay()];

            if (typeof establishment.revenue_centers !== 'undefined') {
                establishment.revenue_centers.forEach(function (item) {
                    if (item.revenue_center_id === 2) {
                        if (typeof item.kitchen_hours !== 'undefined') {
                            item.kitchen_hours.forEach(function (day) {
                                if (day.kitchen_week_day === today) {

                                    let minHour = new Date(new Date().setHours(new Date().getHours() + 2)).getHours();
                                    let firstHour = parseInt(day.kitchen_begins.substring(0, 2));
                                    let firstMinute = parseInt(day.kitchen_begins.substring(3, 5));
                                    let lastHour = parseInt(day.kitchen_ends.substring(0, 2));
                                    let lastMinute = parseInt(day.kitchen_ends.substring(3, 5));
                                    if (minHour >= lastHour) {
                                        console.log('moved to tomorrow');
                                        setMinDate(new Date(new Date().setHours(new Date().getHours() + 24)));
                                        setMaxDate(new Date(new Date().setHours(new Date().getHours() + 144)));
                                    }

                                    setStartHour({
                                        hour: firstHour,
                                        minute: firstMinute
                                    })

                                    setEndHour({
                                        hour: lastHour,
                                        minute: lastMinute === 0 ? 59 : lastMinute
                                    });
                                }
                            });
                        }
                    }
                });
            }
        }
        getDeliveryTimeRange();

        function getScheduledDelivery() {
            let deliverySchedule = JSON.parse(localStorage.getItem('@appgenie/deliverySchedule')) || false;
            if (deliverySchedule) {
                let now = new Date();
                let then = new Date(deliverySchedule);
                if (now < then) {
                    console.log(`valid schedule`);
                    setScheduleDelivery(deliverySchedule);
                } else {
                    console.log(`invalid schedule`);
                    setScheduleDelivery(false);
                }
            } else {
                setScheduleDelivery(false);
            }
        }
        getScheduledDelivery();

        async function getSuggestedTips() {

            var tipsList = [{ percentage: 'None', default: false }];
            const promises = await establishment.tip_suggestion.map(async (item) => {
                tipsList.push({ percentage: item.tip_suggestion_percent, default: item.tip_suggestion_default });
                // if (item.tip_suggestion_default === true) { 
                //     let calcTip = totals.general * (item.tip_suggestion_percent/100);
                //     console.log(totals);
                //     setDefaultTip(item.tip_suggestion_percent);
                //     setDefaultTipCalc(calcTip); 
                // };
            });

            await Promise.all(promises);
            setSuggestedTips(tipsList);
        }
        getSuggestedTips();

        async function getUserCCProfile() {

            let userInfo = JSON.parse(localStorage.getItem('@appgenie/user')) || false;
            if (typeof userInfo.cc_profiles !== 'undefined') {
                
                try {

                    await userInfo.cc_profiles.forEach(function (item) {
                        let payload = {
                            cc_profile_id: item.cc_profile_id,
                            establishment_id: item.cc_profile_fk_establishment_id,
                        }
                        Api.getUserCCProfile(payload)
                            .then(res => {
                                if (res.code === 201) {
                                    // console.log(res);
                                    setUserCards(res.data);

                                    res.data.forEach(function (card) {
                                        if (card.defaultacct === 'Y') {
                                            setDefaultUserCard(card);
                                            setPickedPaymentMethod(2);
                                        }
                                    });
                                } 
                            });

                    });

                } catch (err) {
                    console.log(err);
                }

            } else {
                // console.log('typeof user.cc_profiles');
                // console.log(typeof user.cc_profiles);
                console.log(user);
            }
        }
        getUserCCProfile();

    }, [refreshUserCards], [removeItem], [data], [totals], [appUrl], [revenueCenter], [deliveryAddress], [userCards], [nonCashFeeAmount], [defaultTip, [defaultTipCalc], [establishment]]);

    function handleItemQuantity(item_id, item_name, quantity, operation) {

        if (quantity === 1 && operation === '-') {

            handleOpenRemoveItemDialog(item_name, item_id);
            return false;

        } else {

            let a = JSON.parse(localStorage.getItem('@appgenie/items')) || false;
            if (a) {
                typeof a.items !== 'undefined' && a.items.forEach(function (item) {
                    if (item.item_id === item_id) {
                        if (operation === '+') {
                            item.quantity++;
                        } else {
                            item.quantity--;
                        }
                    }
                });
        
                saveDataToLocalStorage(a);
                cartViewItems();
                
            }

        }
        
    }

    function getPickedModifiers(item) {

        var modifiersList = [];
        typeof item.modifiers !== 'undefined' && item.modifiers.forEach(function (option) {
            if (option.modifier_checked) {
                option.items_modifier.forEach(function (mod) {
                    if (mod.modifier_checked) {
                        modifiersList.push(mod);
                    }
                });
            }
        });

        return modifiersList;
    }

    function handleRemoveDialogStatus() {
        setRemoveDialogStatus(false);
    }

    function handleCloseRemoveDialog() {
        setTotals({
            items: 0,
            modifiers: 0,
            general: 0
        });
        setData([]);
        setRemoveItem(0);
        setRemoveDialogTitle('');
    }

    function handleOpenRemoveItemDialog(item_name, item_id) {
        setRemoveItem(item_id);
        setRemoveDialogTitle(`Remove ${item_name}?`);
        setRemoveDialogStatus(true);
    }

    function handleConfirmRemoveItem() {
        let a = JSON.parse(localStorage.getItem('@appgenie/items')) || false;

        if (a) {
            var index = 0;
            typeof a.items !== 'undefined' && a.items.forEach(function (item) {
                if (item.item_id === removeItem) {
                    a.items.splice(index, 1);
                }
                index++;
            });

            saveDataToLocalStorage(a);
            setRemoveItem(0);
            setRemoveDialogStatus(false);
        }
        

    }

    function saveDataToLocalStorage(data) {
        localStorage.removeItem('@appgenie/items');
        if (data.items.length > 0) {
            localStorage.setItem('@appgenie/items', JSON.stringify(data));
        } 
    }

    function handleCheckout(type) {

        localStorage.setItem('@appgenie/revenueCenter', type);
        if (type === 2) {
            setAddressesDialogShow(true);
        }
    }

    function handleStepShift(step) {
        if (step === 2) {
            userIsLogged();
        }
        setStep(step);
    }

    function userIsLogged() {
        if (typeof user.user_id !== 'undefined') {

            if (user.user_verified === 0 || user.user_verified === false) {

                history.push('/verify-account');

            } else {

                var now = new Date().getTime();
                var setupTime = user.loginTime;

                if (now - setupTime > 24 * 60 * 60 * 1000) {
                    localStorage.setItem('@appgenie/redir', '/cart');
                    history.push('/signin');
                }

                return true;

            }

        } else {
            localStorage.setItem('@appgenie/redir', '/cart');
            history.push('/signin');
        }
    }

    function handleRevenueCenter(type) {
        setRevenueCenter(type);
        handleCheckout(type);
    }

    function handleCloseAddressesDialog() {
        setAddressesDialogShow(false);
        console.log('deliveryAddress');
        console.log(deliveryAddress);
    }

    function handleCloseUserCardsDialog() {
        setUserCardsDialogShow(false);
    }

    function handleDeliveryChoice(type) {
        if (type === 'asap') {
            setAsap(true);
            setEnablePayment(true);
        } else {
            setAsap(false);
        }
    }

    function handleCloseSnackbar() {
        setSnackbarOpen(false);
        if (orderApproved > 0) {
            setEnablePayment(true);
            setStep(3);
        }
    }

    function handlePickedPaymentMethod(method) { 
        setPickedPaymentMethod(method);
        if (method === 1) {
            setDefaultTip(0);
        }
    }

    async function handlePlaceOrder() {

        let cart = await JSON.parse(localStorage.getItem('@appgenie/items')) || [];
        setPlacingOrder(true);
        setEnablePayment(false);
        let total = totals.general.toFixed(2);
        let taxAndFees = (totals.general * establishment.tax).toFixed(2);
        let grandTotal = ((totals.general * establishment.tax) + totals.general).toFixed(2);
        let tip = defaultTipCalc.toFixed(2);
        let deliveryFee = localStorage.getItem('@appgenie/deliveryFee') || 0;
        let nonCashFee = ((grandTotal + defaultTipCalc) * nonCashFeePerc / 100).toFixed(2);
        let pickupParams = {"deliveryType": 1, "delivery_fee": 0};
        let deliveryInfo = JSON.parse(localStorage.getItem('@appgenie/deliveryAddress')) || pickupParams;

        // 1 - cash | 2 - cc
        let paymentMethod = pickedPaymentMethod;
        if (pickedPaymentMethod === 0) {
            console.log('searching payment method');
            if (establishment.payment_methods.indexOf(2) !== -1 && defaultUserCard) {
                paymentMethod = 2;
            } else if (establishment.payment_methods.indexOf(1) !== -1) {
                paymentMethod = 1;
            } 
        } 


        if (table) {
            deliveryInfo = {
                            "user_address_fk_user_id": user.user_id,
                            "user_address_street": "Table " + table,
                            "user_address_complement": null,
                            "user_address_city": establishment.establishment_city,
                            "user_address_zipcode": establishment.establishment_zipcode,
                            "user_address_fk_state_id": 32,
                            "user_address_title": "Table " + table,
                            "user_address_lat": "Table " + table,
                            "user_address_lng": "Table " + table,
                            "state_name": "",
                            "state_abreviations": "NJ",
                            "lat": "",
                            "lng": "",
                            "deliveryType": 3,
                            "newAddress": false,
                            "delivery_fee": 0,
                        }
        }

        if (paymentMethod === 0) {

            setSnackbarMessage('Please choose a payment method');
            setSnackbarType('error');
            setSnackbarOpen(true);
            setEnablePayment(true);
            setPlacingOrder(false);

        } else {

            let paymentInfo = {
                paymentMethod: paymentMethod,
                ccInfo: pickedPaymentMethod == 2 ? defaultUserCard : null
            };

            if (revenueCenter === 2 && typeof deliveryInfo.user_address_street === 'undefined') {
            // if (typeof deliveryAddress.user_address_street === 'undefined' && revenueCenter === 2) {

                setSnackbarMessage('Provide a delivery address or change it to pick up');
                setSnackbarType('error');
                setSnackbarOpen(true);
                setEnablePayment(true);
                // return false;

            } else {

                deliveryFee = deliveryFee === undefined ? 0 : deliveryFee;

                let order = {
                    cart: cart.items,
                    totals: {
                        total: total,
                        taxAndFees: taxAndFees,
                        grandTotal: grandTotal,
                        tip: paymentMethod !== 1 ? tip : 0,
                        deliveryFee: table ? 0 : deliveryFee,
                        nonCashFee: isNaN(nonCashFee) ? 0 : nonCashFee
                    },
                    user: {
                        'user_id': user.user_id,
                        'user_app_id': establishment.establishment_fk_app_id
                    },
                    deliveryInfo: deliveryInfo,
                    establishment: establishment,
                    payment_info: paymentInfo,
                    reward: 0,
                    order_origin: 1,
                }

                // console.log(order);

                let isClosed = await kitchenIsClosed(establishment);
                //console.log('Establishment is closed? '+ !!isClosed);

                let kitchenIsOpened = true;
                if (revenueCenter == 1 || (revenueCenter == 2 && asap === true)) {
                    let dateObj = new Date();
                    let day = dateObj.getDate();
                    let month = dateObj.getMonth() + 1;
                    let year = dateObj.getFullYear();
                    let today = year + "-" + month + "-" + day;
                    let hour = dateObj.getHours();
                    let minute = dateObj.getMinutes();
                    let now = hour.toString() + minute.toString();
                    let payload = {
                        establishment_id: establishment.establishment_id,
                        revenue_center_id: revenueCenter,
                        when_date: today,
                        when_time: now,
                    }
                    kitchenIsOpened = await kitchenIsItOpened(payload);
                    //console.log('revenueCenter is ' + revenueCenter);
                    //console.log('kitchenIsOpened is ' + kitchenIsOpened);
                }

                if (!!isClosed === false && kitchenIsOpened === true) {
                    // console.log('placing order...');
                    await Api.placeOrder(order).then(res => {
                        // console.log(res);
                        if (res.code === 201) {

                            setSnackbarMessage('Your order was successfuly placed ;)');
                            setSnackbarType('success');
                            setSnackbarOpen(true);
                            setOrderApproved(1);
                            clearCart(res.data);
                            localStorage.removeItem('@appgenie/table');

                        } else {

                            setSnackbarMessage('An error has occurred. Try again later');
                            setSnackbarType('error');
                            setSnackbarOpen(true);
                            setOrderApproved(2);
                            setEnablePayment(true);
                        }

                    });
                } else {

                    setSnackbarMessage('Sorry, we are closed now. Try again later.');
                    setSnackbarType('error');
                    setSnackbarOpen(true);
                    setEnablePayment(true);
                }

            }

            setPlacingOrder(false);

        }

    }

    function clearCart(orderNumber) {
        try {

            let confirmedOrder = {
                orderNumber: orderNumber,
                approvedAt: new Date(),
                items: cart.items
            }
            localStorage.setItem('@appgenie/confirmedOrder', JSON.stringify(confirmedOrder));
            localStorage.removeItem('@appgenie/items');
            localStorage.removeItem('@appgenie/newAddress');

        } catch (err) {
            console.log(err);
        }
    }

    // if (cart.length && totals.items === 0) {
    //     return <Loading />
    // }

    return (
        <div className="container">
            <div className="sidebar">
                <Sidebar />
            </div>
            <ConfirmationDialog
                open={removeDialogStatus}
                titleText={removeDialogTitle}
                bodyText={`After confirmation, the item will be removed.`}
                cancelAction={handleRemoveDialogStatus}
                confirmAction={handleConfirmRemoveItem}
                handleClose={handleCloseRemoveDialog}
            />
            {data.length > 0 ?
                <div className="content-cart-item">
                    {step === 1 &&
                        <>
                            <StepHeaderButtons step={1} handleStepShift={handleStepShift} />
                            <UserCartItems 
                                data={data}
                                handleItemQuantity={handleItemQuantity}
                                handleOpenRemoveItemDialog={handleOpenRemoveItemDialog}
                            />
                            <div className="checkoutSums">
                                <p>
                                    <strong>Items total:</strong>
                                    <span>$ {totals.items.toFixed(2)}</span>
                                </p>
                                <p className="checkoutDivisor">
                                    <strong>Options total:</strong>
                                    <span>$ {totals.modifiers.toFixed(2)}</span>
                                </p>
                                <p>
                                    <strong>Subtotal:</strong>
                                    <span>$ {totals.general.toFixed(2)}</span>
                                </p>
                                <p className="checkoutDivisor">
                                    <strong>Taxes:</strong>
                                    <span>$ {(totals.general * establishment.tax).toFixed(2)}</span>
                                </p>
                                <p><strong>Total:</strong>
                                    <span>$ {((totals.general * establishment.tax) + totals.general).toFixed(2)}</span>
                                </p>
                            </div>
                            <CheckoutButton onClick={() => handleStepShift(2)}>
                                Checkout: $ {((totals.general * establishment.tax) + totals.general).toFixed(2)}
                            </CheckoutButton>
                        </>
                    }

                    {step === 2 &&
                        <>
                            <StepHeaderButtons step={2} handleStepShift={handleStepShift} />
                            {table ?
                            <div className='table-service'>Table Service: table {table}</div>
                            :
                            <ButtonGroup variant="outlined" aria-label="outlined primary button group" fullWidth={true}>
                                {establishment.revenue_centers.map((item, index) => (
                                    <Button
                                        key={index}
                                        variant="contained"
                                        style={revenueCenter === item.revenue_center_id ? { backgroundColor: blue[900], color: '#FFFFFF' } : { backgroundColor: '#efefef', color: '#666' }}
                                        onClick={() => handleRevenueCenter(item.revenue_center_id)}
                                    >
                                        {item.revenue_center_name}
                                    </Button>
                                ))}
                            </ButtonGroup>
                            }

                            {deliveryAddress !== null &&
                                <>
                                {typeof deliveryAddress.user_address_street !== 'undefined' && revenueCenter === 2 &&
                                    <div className='picked-address'>
                                        <h5>Your delivery address is:</h5>
                                        <p>{deliveryAddress.user_address_street} {deliveryAddress.user_address_complement}
                                            - {deliveryAddress.user_address_city}/{deliveryAddress.state_abreviations}</p>
                                        <p>Zip: {deliveryAddress.user_address_zipcode}</p>
                                        <em>Delivery fee: US$ {deliveryFee.toFixed(2)}</em>
                                        <span className="change-address-link" onClick={() => setAddressesDialogShow(true)}>Change address</span>

                                        {/* {typeof establishment.establishment_asap_delivery !== 'undefined' &&
                                            revenueCenter === 2 &&
                                            establishment.establishment_asap_delivery === true && */}
                                        {revenueCenter === 2 &&
                                            <ButtonGroup
                                                variant="contained"
                                                fullWidth={true}
                                                style={{ marginTop: 30, position: 'relative' }}
                                            >

                                                <Button
                                                    variant="text"
                                                    onClick={() => { handleDeliveryChoice('asap') }}
                                                    style={asap === true ? { backgroundColor: blue[900], color: 'white' } : { backgroundColor: 'white', color: '#666' }}
                                                >
                                                    ASAP
                                                </Button>

                                                <Button
                                                    variant="text"
                                                    onClick={() => { handleDeliveryChoice('schedule') }}
                                                    style={{ color: '#666' }}
                                                >
                                                    {asap === true ?
                                                        <span>Schedule</span>
                                                        :
                                                        <Scheduler
                                                            minDate={minDate}
                                                            maxDate={maxDate}
                                                            startHour={startHour}
                                                            endHour={endHour}
                                                            setSnackbarMessage={setSnackbarMessage}
                                                            setSnackbarOpen={setSnackbarOpen}
                                                            setSnackbarType={setSnackbarType}
                                                            setEnablePayment={setEnablePayment}
                                                            scheduledDelivery={!scheduledDelivery ? `` : scheduledDelivery}
                                                        />
                                                    }
                                                </Button>

                                                {enablePayment === true && asap === false && scheduledDelivery &&
                                                    <FiCheckCircle color="green" size={32} style={{ position: "absolute", bottom: 10, right: 0 }} />
                                                }
                                                {enablePayment === false && asap === false && !scheduledDelivery &&
                                                    <FiAlertCircle color="red" size={32} style={{ position: "absolute", bottom: 10, right: 0 }} />
                                                }

                                            </ButtonGroup>
                                        }
                                    </div>

                                }
                                </>
                            }

                            <div className="paymentSummary">


                                <div className="paymentMethodBlock">
                                    <div className="paymentMethodTitle">
                                        Payment Method
                                    </div>
                                    <div className="paymentMethod">
                                        {establishment.payment_methods.indexOf(2) !== -1 && defaultUserCard && pickedPaymentMethod !== 1 ?
                                            <>
                                                <div className="paymentMethodColumn">
                                                    {defaultUserCard.accttype === 'VISA' &&
                                                        <Visa className='user-card' />
                                                    }
                                                    {defaultUserCard.accttype === 'MC' &&
                                                        <Mastercard className='user-card' />
                                                    }
                                                </div>
                                                <div className="paymentMethodColumn">
                                                    <strong>**** {typeof defaultUserCard.token !== 'undefined' && defaultUserCard.token.substr(-4)}</strong>
                                                </div>
                                            </>
                                            :
                                            <>
                                                {establishment.payment_methods.indexOf(1) !== -1 &&
                                                <>
                                                    <FaCoins style={{ marginRight: 20 }} />
                                                    <div className="paymentMethodColumn">
                                                        Pay with cash
                                                    </div>
                                                </>
                                                }
                                            </>
                                        }
                                        
                                        <div className="paymentMethodColumn">
                                            {userCards.length > 0 ?
                                                <Button
                                                    style={{ backgroundColor: blue[600], color: 'white', marginLeft: 25 }}
                                                    onClick={() => setUserCardsDialogShow(true)}
                                                >
                                                    Change
                                                </Button>
                                            :
                                                <Button
                                                    style={{ backgroundColor: blue[600], color: 'white', marginLeft: 25 }}
                                                    onClick={() => setUserCardsDialogShow(true)}
                                                >
                                                    Click to add a card
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {pickedPaymentMethod === 2 &&
                                    <OptionsButtons
                                        options={suggestedTips}
                                        defaultValue={defaultTip}
                                        setDefaultTip={setDefaultTip}
                                        setDefaultTipCalc={setDefaultTipCalc}
                                        totalAmount={totals.general}
                                    />
                                }

                                <div className="checkoutSums">
                                    <p>
                                        <strong>Subtotal:</strong>
                                        <span>$ {totals.general.toFixed(2)}</span>
                                    </p>
                                    <p className="checkoutDivisor">
                                        <strong>Taxes:</strong>
                                        <span>$ {(totals.general * establishment.tax).toFixed(2)}</span>
                                    </p>
                                    {pickedPaymentMethod === 2 &&
                                        <>
                                            <p className="checkoutDivisor">
                                                <strong>Tip:</strong>
                                                <span>$ {defaultTipCalc.toFixed(2)}</span>
                                            </p>
                                            {nonCashFeeAmount > 0 && 
                                                <p className="checkoutDivisor">
                                                    <strong>Non cash fee:</strong>
                                                    <span>$ {(nonCashFeeAmount + (defaultTipCalc * nonCashFeePerc/100)).toFixed(2)}</span>
                                                </p>
                                            }
                                        </>
                                    }
                                    {revenueCenter === 2 && deliveryFee > 0 ?
                                        <>
                                            <p className="checkoutDivisor">
                                                <strong>Delivery fee:</strong>
                                                <span>$ {deliveryFee.toFixed(2)}</span>
                                            </p>
                                            {pickedPaymentMethod === 2 ?
                                            <p><strong>Total:</strong>
                                                <span>
                                                    $ {
                                                    (((totals.general * establishment.tax) + totals.general + defaultTipCalc + deliveryFee)
                                                    + 
                                                    (((totals.general * establishment.tax) + totals.general ) * nonCashFeePerc/100)).toFixed(2)
                                                    }
                                                </span>
                                            </p>
                                            :
                                            <p><strong>Total:</strong>
                                                <span>$ {((totals.general * establishment.tax) + totals.general + deliveryFee).toFixed(2)}</span>
                                            </p>
                                            }
                                        </>
                                        :
                                        <>
                                        {pickedPaymentMethod === 2 ?
                                        <p><strong>Total:</strong>
                                            <span>
                                                $ {
                                                (((totals.general * establishment.tax) + totals.general + defaultTipCalc)
                                                + nonCashFeeAmount).toFixed(2)
                                                }
                                            </span>
                                        </p>
                                        :
                                        <p><strong>Total:</strong>
                                            <span>$ {((totals.general * establishment.tax) + totals.general).toFixed(2)}</span>
                                        </p>
                                        }
                                        </>
                                    }
                                </div>
                            </div>

                            {revenueCenter &&
                                <button
                                    className="confirmBuy"
                                    onClick={handlePlaceOrder}
                                    disabled={!enablePayment}
                                    style={{ backgroundColor: !enablePayment ? '#e0e0e0' : 'royalblue' }}
                                >
                                    {placingOrder ?
                                        <CircularProgress size={22} color='#ffffff' />
                                        :
                                        <span>PLACE ORDER</span>
                                    }

                                </button>
                            }
                        </>
                    }
                    {step === 3 &&
                        <div className='finishedOrder'>
                            {orderApproved === 1 &&
                                <h2>We received your order and your dish is already being prepared =D</h2>
                            }
                            {orderApproved === 2 &&
                                <h2 style={{ color: 'red' }}>Sorry, your order was not approved. =( </h2>
                            }
                            <img src={establishment.establishment_logo} style={{ width: 300, height: 'auto' }} />
                        </div>
                    }
                </div>
                :
                <div className="empty-cart">
                    <FiAlertCircle size={50} />
                    <h1>Your shopping cart is empty</h1>
                    <h4>How about adding some delicious dishes?</h4>
                    <Link to={`/app/${appUrl}`} className="container-item">
                        Show me them now!
                    </Link>
                </div>
            }

            <DeliveryAddresses
                dialogShow={addressesDialogShow}
                handleCloseDialog={handleCloseAddressesDialog}
                selected={false}
                loading={false}
                user={JSON.parse(localStorage.getItem('@appgenie/user')) || []}
                getDeliveryAddress={setDeliveryAddress}
                setDeliveryFee={setDeliveryFee}
            />

            <UserPaymentMethods
                dialogShow={userCardsDialogShow}
                handleCloseDialog={handleCloseUserCardsDialog}
                selected={false}
                user={JSON.parse(localStorage.getItem('@appgenie/user')) || false}
                userCards={userCards}
                setDefaultUserCard={setDefaultUserCard}
                setUserCards={setUserCards}
                establishment={establishment}
                setPickedPaymentMethod={handlePickedPaymentMethod}
                setRefreshUserCards={setRefreshUserCards}
            />

            <SnackBarNotification
                handleClose={handleCloseSnackbar}
                open={snackbarOpen}
                vertical={`bottom`}
                horizontal={`right`}
                message={snackbarMessage}
                type={snackbarType}
            />

        </div>
    )
}